import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Router from 'next/router';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import { publish } from '@/utils/publish';
import { PatternFormat } from 'react-number-format';

import styles from './AdvancedSearch.module.scss';

import { config } from '@/config';
import { setSessionItem, getSessionItem, removeSessionItem } from '@/utils/storage';
import { parseDateUTC, validateDay, toPriceCurrency, setStateAsync } from '@/utils/helpers';
import { clearContacts } from '@/store/actions/contacts.actions';
import Wrapper from '@/components/Wrapper';
import CurrencyInput from '@/components/Helpers/CurrencyInput';
import BottomSidebar from '@/components/BottomSidebar';
import {
  TYPE_OPTIONS,
  AGE_OPTIONS,
  INCLUDE_EXCLUDE_OPTIONS,
  COLORS_OPTIONS,
  DATE_OPTIONS,
  CONTACT_POLICY_STATUSES,
  SELECT_TYPES,
  CONTACT_PRODUCTS_IN_HOUSE,
  TM_UIDS,
  MONTHS,
  GENDERS,
  AFFINITY,
  DATA_TYPE,
  PLAN_TYPE,
  ADVANCED_SEARCH,
  ADVANCED_SEARCH_FIELDS,
  RELATIONSHIP_OPTIONS,
  FUNERAL_SERVICE,
  DISPOSITION_OF_REMAINS,
  LANGUAGE,
  ADDITIONAL_DETAILS_GIVEN_TO,
  ADDITIONAL_DETAILS_STATUSES,
  SECURITIES_SOURCE,
  SECURITIES_TYPE,
  AML,
  RM_PRODUCTS,
  RM_COMMISSIONS,
  RM_CONTACT_ROLES,
  CONTACT_DISPOSITIONS,
  RX_DETAILS_PLAN_TYPES,
  RX_DETAILS_RECORD_TYPES,
  RX_DETAILS_LETTER_SENT,
  TOBACCO
} from '@/store/models/contact';
import { POLICY_TYPES, POLICY_STATUSES, REPLACEMENT } from '@/store/models/policy';
import { TYPES, TYPES_FOR_ADMIN } from '@/store/models/import';

import { fetchCarriers } from '@/store/actions/other.actions';
import { searchUsers } from '@/store/actions/search.actions';
import { fetchTags, fetchStatuses, fetchSources } from '@/store/actions/contacts.actions';
import { withAuthSync } from '@/utils/auth';
import { toPhoneFormat, toZipCodeFormat } from '@/utils/helpers';
import { debounce, random } from '@/utils/helpers';
import AdvancedSearchItem from '@/components/Contacts/advanced-search/AdvancedSearchItem';
import AdvancedSearchItemDate from '@/components/Contacts/advanced-search/AdvancedSearchItemDate';
import { MAIN_OR_BRANCH } from '@/store/models/profile';
import RmAdvancedSearchItems from '@/components/Contacts/advanced-search/RMAdvancedSearchItems';
import { RM_ADVANCED_SEARCH } from '@/store/models/contact';
import { ActiveBadgeList, ActiveBadgeItem, ActiveBadgeContent } from '@/components/Contacts/ActiveBadge';

export const LOCAL_STORAGE_DUPLICATE_KEY = 'advanced_search_duplicate';
export const LOCAL_STORAGE_SEARCH_KEY = 'advanced_search_form';
const ownerId = 'ownerId';

class Index extends Component {
  fetchTags = async () => {
    await this.props.fetchTags({ q: this.state.tag }).catch(res => publish('error', res));
  };

  isRM = this.props.currentUser.regional_manager;
  isRmCIA = this.isRM && config().CAREINADVANCE;

  search = React.createRef();
  initState = {
    duplicateForm: {
      is_duplicate: false,
      fields: {
        first_name: true,
        last_name: false,
        address: false
      }
    },
    advancedSearchForm: [
      ...ADVANCED_SEARCH.map(el => {
        return {
          field: el.field,
          type: el.type,
          value: ''
        };
      }),
      ...RM_ADVANCED_SEARCH.map(el => {
        return {
          field: el.field,
          type: el.type,
          value: ''
        };
      })
    ],
    carriers: [],
    user_id: [],
    exclude_owners: false,
    searches: [],
    zip_code: [],
    ba_zip_code: [],
    home_address_zip_code: [],
    writing_agent_1_name: [],
    writing_agent_2_name: [],
    writing_agent_3_name: [],
    writing_agent_1_number: [],
    writing_agent_2_number: [],
    writing_agent_3_number: [],
    tag: '',
    includeAllCarriers: false,
    excludeAllCarriers: false
  };

  state = {
    debouncedTagSearch: debounce(this.fetchTags),

    ...JSON.parse(JSON.stringify(this.initState)),
    aboutVisible: true,
    customStyles: {
      multiValueLabel: styles => ({
        ...styles
      }),
      multiValueRemove: styles => ({
        ...styles,
        transition: '0.1s',
        cursor: 'pointer',
        ':hover': {
          opacity: 0.7
        }
      })
    }
  };

  setStateAsync = setStateAsync.bind(this);

  async componentDidMount() {
    this.fetchCarriers();
    this.props.clearContacts();
    await this.props.fetchStatuses().catch(res => publish('error', res));
    await this.props.fetchSources({ with_h: true }).catch(res => publish('error', res));
    await this.props.fetchTags().catch(res => publish('error', res));

    const searches = JSON.parse(getSessionItem('searches'));
    if (searches) {
      this.setState({ searches }, () => this.performSearch());
    }

    const savedFormInSession = (JSON.parse(getSessionItem(LOCAL_STORAGE_SEARCH_KEY)) || []).reduce((acc, cur) => {
      if (cur.type === 'include' || cur.type === 'exclude') {
        acc[`${cur.field}_${cur.type}`] = cur;
      } else {
        acc[cur.field] = cur;
      }
      return acc;
    }, {});

    const advancedSearch = this.state.advancedSearchForm.map(formField => {
      if (formField.type === 'include' || formField.type === 'exclude') {
        return savedFormInSession[`${formField.field}_${formField.type}`] ?? formField;
      }
      return savedFormInSession[formField.field] ?? formField;
    });

    const duplicateForm = JSON.parse(getSessionItem(LOCAL_STORAGE_DUPLICATE_KEY));
    if (duplicateForm) {
      await this.setStateAsync({ duplicateForm });
    }

    if (advancedSearch && advancedSearch.length > 0) {
      let advancedSearchForm = advancedSearch
        .filter(
          el =>
            (el.field !== 'writing_agent_1_number' &&
              el.field !== 'writing_agent_2_number' &&
              el.field !== 'writing_agent_3_number' &&
              !el.field.includes('zip_code')) ||
            (el.field === 'zip_code' && (el.type === 'empty' || el.type === 'not_empty')) ||
            (el.field === 'home_address_zip_code' && (el.type === 'empty' || el.type === 'not_empty')) ||
            (el.field === 'ba_zip_code' && (el.type === 'empty' || el.type === 'not_empty'))
        )
        .map(el => {
          const field = el.field;
          const type = el.type;
          if (el.value.length > 0) {
            if (type === 'empty' || type === 'not_empty' || type === 'none')
              return {
                field,
                type,
                value: ''
              };
            else if (field === 'status_id') {
              const value = this.props.statuses.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'user_id') {
              if (type === 'exclude') {
                this.setState({ exclude_owners: el.value.length });
              }
              return el;
            } else if (field === 'source_id') {
              const value = this.props.sources.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'original_source_id') {
              const value = this.props.sources.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'color') {
              const value = COLORS_OPTIONS.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'products_in_house') {
              const value = CONTACT_PRODUCTS_IN_HOUSE.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'tm_uid') {
              const value = TM_UIDS.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'products') {
              const value = RM_PRODUCTS.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'commissions') {
              const value = RM_COMMISSIONS.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'funereal_services') {
              const value = FUNERAL_SERVICE.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'remains_dispositions') {
              const value = DISPOSITION_OF_REMAINS.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'languages') {
              const value = LANGUAGE.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'dob_month') {
              const value = MONTHS.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'tobacco') {
              if (type === 'include') {
                return {
                  field,
                  type: 'include',
                  value: Array.isArray(el.value)
                    ? el.value?.map(option =>
                        option === 'yes' ? { value: 'yes', label: 'Yes' } : { value: 'no', label: 'No' }
                      )
                    : []
                };
              }
            } else if (field === 'data_capture_data_type') {
              const value = DATA_TYPE.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'main_or_branch') {
              const value = MAIN_OR_BRANCH.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'data_capture_plan_type') {
              const value = PLAN_TYPE.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'data_capture_carrier') {
              const value = this.normaliseOptions(this.state.carriers).filter(data => el.value.includes(data.value));
              if (value.length === this.state.carriers.length) {
                this.setState({ [`${type}AllCarriers`]: true });
                return {
                  field,
                  type,
                  value: [{ value: 'all', label: 'All carriers' }]
                };
              }
              return {
                field,
                type,
                value
              };
            } else if (field === 'rx_details_plan_type') {
              const value = RX_DETAILS_PLAN_TYPES.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'rx_details_record_type') {
              const value = RX_DETAILS_RECORD_TYPES.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'rx_details_letter_sent') {
              const value = RX_DETAILS_LETTER_SENT.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'replacement') {
              const value = REPLACEMENT.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'policy_type') {
              const value = POLICY_TYPES(this.props.currentUser).filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'import_type') {
              const importType = this.props.currentUser.admin ? TYPES_FOR_ADMIN : TYPES;
              const value = importType.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'policy_status') {
              const value = CONTACT_POLICY_STATUSES(this.props.currentUser).filter(data =>
                el.value.includes(data.value)
              );
              return {
                field,
                type,
                value
              };
            } else if (field === 'given_to') {
              const value = ADDITIONAL_DETAILS_GIVEN_TO.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'info_status') {
              const value = ADDITIONAL_DETAILS_STATUSES.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'security_type') {
              const value = SECURITIES_TYPE.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'security_source') {
              const value = SECURITIES_SOURCE.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'policies_status') {
              const value = POLICY_STATUSES(this.props.currentUser).filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'role') {
              const value = RM_CONTACT_ROLES.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'gender') {
              const value = GENDERS.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'affinity') {
              const value = AFFINITY.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'aml') {
              const value = AML.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'survivor_relationship') {
              const value = RELATIONSHIP_OPTIONS.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'spouse_gender') {
              const value = GENDERS.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'disposition') {
              const value = CONTACT_DISPOSITIONS.filter(data => el.value.includes(data.value));
              return {
                field,
                type,
                value
              };
            } else if (field === 'writing_agent_1_name') {
              this.setUserValue('writing_agent_1_name', el.value);
              return el;
            } else if (field === 'writing_agent_2_name') {
              this.setUserValue('writing_agent_2_name', el.value);
              return el;
            } else if (field === 'writing_agent_3_name') {
              this.setUserValue('writing_agent_3_name', el.value);
              return el;
            } else {
              return el;
            }
          } else return el;
        });
      if (!advancedSearchForm.find(el => el.field === 'zip_code')) {
        await advancedSearchForm.push({
          field: 'zip_code',
          type: 'exactly',
          value: ''
        });
      }
      if (!advancedSearchForm.find(el => el.field === 'ba_zip_code')) {
        await advancedSearchForm.push({
          field: 'ba_zip_code',
          type: 'exactly',
          value: ''
        });
      }
      if (!advancedSearchForm.find(el => el.field === 'home_address_zip_code')) {
        await advancedSearchForm.push({
          field: 'home_address_zip_code',
          type: 'exactly',
          value: ''
        });
      }
      const zip_code =
        (await advancedSearch.filter(
          el => el.field === 'zip_code' && el.value.length > 0 && el.type !== 'empty' && el.type !== 'not_empty'
        )) || [];
      const ba_zip_code =
        (await advancedSearch.filter(
          el => el.field === 'ba_zip_code' && el.value.length > 0 && el.type !== 'empty' && el.type !== 'not_empty'
        )) || [];
      const home_address_zip_code =
        (await advancedSearch.filter(
          el =>
            el.field === 'home_address_zip_code' &&
            el.value.length > 0 &&
            el.type !== 'empty' &&
            el.type !== 'not_empty'
        )) || [];
      if (!advancedSearchForm.find(el => el.field === 'writing_agent_1_number')) {
        await advancedSearchForm.push({
          field: 'writing_agent_1_number',
          type: 'include',
          value: ''
        });
      }
      const writing_agent_1_number =
        (await advancedSearch.filter(el => el.field === 'writing_agent_1_number' && el.value.length > 0)) || [];
      if (!advancedSearchForm.find(el => el.field === 'writing_agent_2_number')) {
        await advancedSearchForm.push({
          field: 'writing_agent_2_number',
          type: 'include',
          value: ''
        });
      }
      const writing_agent_2_number =
        (await advancedSearch.filter(el => el.field === 'writing_agent_2_number' && el.value.length > 0)) || [];
      if (!advancedSearchForm.find(el => el.field === 'writing_agent_3_number')) {
        await advancedSearchForm.push({
          field: 'writing_agent_3_number',
          type: 'include',
          value: ''
        });
      }
      const writing_agent_3_number =
        (await advancedSearch.filter(el => el.field === 'writing_agent_3_number' && el.value.length > 0)) || [];
      await this.setStateAsync({
        advancedSearchForm,
        writing_agent_1_number,
        writing_agent_2_number,
        writing_agent_3_number,
        zip_code,
        ba_zip_code,
        home_address_zip_code
      });
    }
  }

  componentDidUpdate(_, prevState) {
    if (prevState.tag !== this.state.tag) {
      this.state.debouncedTagSearch();
    }

    if (prevState.excludeAllCarriers && this.state.includeAllCarriers) {
      this.setState({ excludeAllCarriers: false });
    } else if (prevState.includeAllCarriers && this.state.excludeAllCarriers) {
      this.setState({ includeAllCarriers: false });
    }
  }

  value = field => {
    return this.state.advancedSearchForm.find(el => el.field === field)?.value;
  };

  valueIncludeExclude = (field, type) => {
    return this.state.advancedSearchForm.find(el => el.field === field && el.type === type)?.value;
  };

  valueRangeStart = field => {
    const object = this.state.advancedSearchForm.find(el => el.field === field)?.value;
    return object[0] && object[0];
  };

  valueRangeEnd = field => {
    const object = this.state.advancedSearchForm.find(el => el.field === field)?.value;
    return object[1] && object[1];
  };

  type = field => {
    return this.state.advancedSearchForm.find(el => el.field === field)?.type;
  };

  changeBaseInput = (event, additionalParams = {}) => {
    const input = event.target;
    let value = input.value;
    let changedObject = this.state.advancedSearchForm.find(el => el.field === input.name);

    if (additionalParams.withPhoneFormat) {
      value = toPhoneFormat(value);
    }
    if (additionalParams.withZipCodeFormat) {
      value = toZipCodeFormat(value);
    }
    changedObject.value = value;

    this.setForm(changedObject);
  };

  submitOnEnter = event => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      this.onSubmit();
    }
  };

  changeSelect = (obj, name, type) => {
    let changedObject = this.state.advancedSearchForm.find(el => el.field === name && el.type === type);
    changedObject.value = obj;

    const newForm = this.state.advancedSearchForm.map(el => {
      if (el.field === changedObject.field && el.field === type) {
        return changedObject;
      } else {
        return el;
      }
    });

    this.setState({ advancedSearchForm: [...newForm] });
  };

  // Only works with "Carriers" for now
  changeSelectWithAll = (obj, name, type) => {
    let changedObject = this.state.advancedSearchForm.find(el => el.field === name && el.type === type);
    changedObject.value = obj || [];
    const newForm = this.state.advancedSearchForm.map(el => {
      if (el.field === changedObject.field && el.field === type) {
        return changedObject;
      } else {
        return el;
      }
    });

    this.setState({
      advancedSearchForm: [...newForm],
      [`${type}AllCarriers`]: !obj || !obj?.length ? false : this.state[`${type}AllCarriers`]
    });
  };

  isAnyAllCarriersSelected = () => this.state.includeAllCarriers || this.state.excludeAllCarriers;

  isAllCarriersSelectedFor = type => {
    let carriers = this.valueIncludeExclude('data_capture_carrier', type);
    return carriers ? carriers?.some(el => el.value === 'all') : false;
  };

  selectAllCarriers = (event, type) => {
    const newForm = this.state.advancedSearchForm.map(el => {
      if (el.field === 'data_capture_carrier') {
        if (el.type === type && event.target.checked) {
          return { ...el, value: [{ value: event.target.value, label: 'All carriers' }] };
        }
        return { ...el, value: [] };
      }
      return el;
    });

    this.setState({
      advancedSearchForm: [...newForm],
      [`${type}AllCarriers`]: event.target.checked
    });
  };

  changeTags = (tags, type) => {
    const normalisedTags = tags
      ? tags.map(el => {
          return {
            ...el,
            label: el.label?.props?.children[0] || el.label
          };
        })
      : [];
    let changedObject = this.state.advancedSearchForm.find(el => el.field === 'tags' && el.type === type);
    changedObject.value = normalisedTags || [];

    const newForm = this.state.advancedSearchForm.map(el => {
      if (el.field === changedObject.field && el.field === type) {
        return changedObject;
      } else {
        return el;
      }
    });

    this.setState({ advancedSearchForm: [...newForm] });
  };

  changeBaseIncludeExclude = (event, type) => {
    const input = event.target;

    let changedObject = this.state.advancedSearchForm.find(el => el.field === input.name && el.type === type);
    changedObject.value = input.value;

    const newForm = this.state.advancedSearchForm.map(el => {
      if (el.field === changedObject.field && el.field === type) {
        return changedObject;
      } else {
        return el;
      }
    });

    this.setState({
      advancedSearchForm: [...newForm]
    });
  };

  changeBoolean = name => {
    let changedObject = this.state.advancedSearchForm.find(el => el.field === name);
    changedObject.value = !changedObject.value;
    this.setForm(changedObject);
  };

  excludeOwners = async () => {
    let includeObject = this.state.advancedSearchForm.find(el => el.field === 'user_id' && el.type === 'include');
    let excludeObject = this.state.advancedSearchForm.find(el => el.field === 'user_id' && el.type === 'exclude');
    await this.setStateAsync({ exclude_owners: !this.state.exclude_owners });
    if (this.state.exclude_owners) {
      this.changeSelect(includeObject.value, 'user_id', 'exclude');
      this.changeSelect([], 'user_id', 'include');
    } else {
      this.changeSelect(excludeObject.value, 'user_id', 'include');
      this.changeSelect([], 'user_id', 'exclude');
    }
  };

  changeType = (changedObj, e) => {
    let changedObject = this.state.advancedSearchForm.find(el => el.field === e.name);

    if (changedObj.value === 'empty' || changedObj.value === 'not_empty') {
      changedObject.value = '';
    }
    changedObject.type = changedObj.value;

    this.setForm(changedObject);
  };

  changeIncludeExcludeValue = (changedObj, e) => {
    let newObject = this.state.advancedSearchForm.find(el => el.field === e.name);

    newObject.value = changedObj.value === 'all' ? '' : true;
    newObject.type = changedObj.value;

    this.setForm(newObject);
  };

  changeDateType = (changedObj, e) => {
    let changedObject = this.state.advancedSearchForm.find(el => el.field === e.name);
    changedObject.type = changedObj.value;
    changedObject.value = '';

    this.setForm(changedObject);
  };

  normaliseOptions = options => {
    return options.map(el => {
      return {
        value: el.value,
        label: el.label.replace(/^(=|!=)/, '')
      };
    });
  };

  normaliseTagOptions = options => {
    return options.map(el => {
      return {
        value: el.label,
        label: <div className="tag-option">{el.label} </div>,
        color: el.color || ''
      };
    });
  };

  changeDate = (dateObj, name) => {
    let changedObject = this.state.advancedSearchForm.find(el => el.field === name);
    if (name === 'dob_day' || name === 'policy_effective_day') {
      changedObject.value = dateObj.target.value;
      return this.setForm(changedObject);
    }

    changedObject.value = moment(dateObj).format('MM/DD/YYYY');

    this.setForm(changedObject);
  };

  changeDateRangeStart = (dateObj, name) => {
    let changedObject = this.state.advancedSearchForm.find(el => el.field === name);
    if (name === 'dob_day' || name === 'policy_effective_day') {
      changedObject.value = [dateObj.target.value];
      return this.setForm(changedObject);
    }

    changedObject.value = [moment(dateObj).format('MM/DD/YYYY')];

    this.setForm(changedObject);
  };

  changeDateRangeEnd = (dateObj, name) => {
    let changedObject = this.state.advancedSearchForm.find(el => el.field === name);
    if (name === 'dob_day' || name === 'policy_effective_day') {
      changedObject.value = [...changedObject.value.slice(0, 1), dateObj.target.value];
      return this.setForm(changedObject);
    }

    changedObject.value = changedObject.value[1]
      ? [...changedObject.value.slice(0, 1), moment(dateObj).format('MM/DD/YYYY')]
      : [...changedObject.value, moment(dateObj).format('MM/DD/YYYY')];

    this.setForm(changedObject);
  };

  changeCurrency = (fieldName, value) => {
    let obj = this.state.advancedSearchForm.find(({ field }) => fieldName === field);
    if (obj) obj.value = toPriceCurrency(value);
    this.setForm(obj);
  };

  fetchCarriers = async () => {
    const carriersList = await this.props.fetchCarriers().catch(res => publish('error', res));
    if (carriersList?.data?.length > 0) {
      const carriers = carriersList?.data.map(el => {
        return { value: el.id, label: el.name };
      });
      this.setState({
        carriers
      });
    }
  };

  setForm = changedObject => {
    const newForm = this.state.advancedSearchForm.map(el => {
      if (el.field === changedObject.field) {
        return changedObject;
      } else {
        return el;
      }
    });

    this.setState({
      advancedSearchForm: [...newForm]
    });
  };

  tagsOptions = tags => {
    return tags.map(tag => ({
      tag,
      value: tag.name,
      label: tag.name,
      color: tag.color
    }));
  };

  loadOwners = async inputValue => {
    const owners = await this.props.searchUsers(inputValue, true);
    const data = this.ownersOptions(owners.data);
    return new Promise(resolve => resolve(data));
  };

  ownersOptions = owners => {
    if (owners.length > 0) {
      return owners.map(owner => ({
        value: owner.id,
        label: `${owner.first_name} ${owner.last_name}`
      }));
    }
  };

  validOptions = (options, valueToFilter) => {
    if (valueToFilter) {
      const filteredValues = valueToFilter.map(el => el.value);
      return options.filter(el => !filteredValues.includes(el.value));
    } else {
      return options;
    }
  };

  changeUser = (fieldName, users) => {
    let obj = this.state.advancedSearchForm.find(({ field }) => {
      return field === fieldName;
    });
    if (obj) obj.value = users && users.length > 0 ? users : [];
    this.setForm(obj);
    this.setUserValue(fieldName, users);
  };

  setUserValue = async (field, users) => {
    if (users && users.length > 0) {
      await this.setStateAsync({
        [field]: users
      });
    } else {
      this.setState({ [field]: [] });
    }
  };

  checkIncludesField = el => {
    return (
      (el.field === 'status_id' ||
        el.field === 'source_id' ||
        el.field === 'original_source_id' ||
        el.field === 'color' ||
        el.field === 'policy_status' ||
        el.field === 'given_to' ||
        el.field === 'info_status' ||
        el.field === 'security_source' ||
        el.field === 'security_type' ||
        el.field === 'policies_status' ||
        el.field === 'role' ||
        el.field === 'products_in_house' ||
        el.field === 'tm_uid' ||
        el.field === 'products' ||
        el.field === 'dob_month' ||
        el.field === 'data_capture_data_type' ||
        el.field === 'data_capture_plan_type' ||
        el.field === 'data_capture_carrier' ||
        el.field === 'policy_type' ||
        el.field === 'import_type' ||
        el.field === 'gender' ||
        el.field === 'affinity' ||
        el.field === 'aml' ||
        el.field === 'survivor_relationship' ||
        el.field === 'funereal_services' ||
        el.field === 'remains_dispositions' ||
        el.field === 'languages' ||
        el.field === 'replacement' ||
        el.field === 'rx_details_plan_type' ||
        el.field === 'rx_details_record_type' ||
        el.field === 'rx_details_letter_sent' ||
        el.field === 'disposition' ||
        el.field === 'spouse_gender') &&
      el.value &&
      el.value.length > 0
    );
  };

  onReset = async () => {
    await this.setStateAsync({
      ...this.initState,
      duplicateForm: JSON.parse(JSON.stringify(this.initState.duplicateForm)),
      advancedSearchForm: this.initState.advancedSearchForm.map(el => {
        return {
          field: el.field,
          type: el.type,
          value: ''
        };
      })
    });
    setSessionItem(LOCAL_STORAGE_DUPLICATE_KEY, JSON.stringify(this.state.duplicateForm));
    setSessionItem(LOCAL_STORAGE_SEARCH_KEY, JSON.stringify(this.state.advancedSearchForm));
    publish('success', 'Advanced Search was successfully reset.');
    this.fetchCarriers();
    this.clearSearch();
    this.search.current.value = '';
  };

  get duplicateForm() {
    return (
      <form autoComplete="off" name="duplicateForm" className={styles.duplicateForm}>
        <div className={styles.checkboxWrapper}>
          <div className={classNames('field isduplicate', styles.field)}>
            <label htmlFor="isDuplicate">
              <p className={classNames('title', styles.isDuplicateWrapper__title)}>Is Duplicate</p>
            </label>
            <input
              id="isDuplicate"
              type="checkbox"
              name="isDuplicate"
              className="is-checkradio is-medium"
              onChange={() =>
                this.setState({
                  duplicateForm: {
                    ...this.state.duplicateForm,
                    is_duplicate: !this.state.duplicateForm.is_duplicate
                  }
                })
              }
              checked={this.state.duplicateForm.is_duplicate}
            />
            <label htmlFor="isDuplicate" />
          </div>
        </div>
        {this.state.duplicateForm.is_duplicate && (
          <>
            {/* <div className={styles.isDuplicateWrapper}>
                <div className={classNames('field')}>
                  <div className={styles.isDuplicateWrapper}>
                    <input
                      className="is-checkradio is-medium"
                      id="mine"
                      type="radio"
                      name="mine"
                      checked={this.state.duplicateForm.mine}
                      onChange={() =>
                        this.setState({
                          duplicateForm: {
                            ...this.state.duplicateForm,
                            mine: !this.state.duplicateForm.mine,
                            globally: !this.state.duplicateForm.globally
                          }
                        })
                      }
                    />
                    <label className="radio" htmlFor="mine">
                      <p className={classNames('title', styles.isDuplicateWrapper__title)}>Mine</p>
                    </label>
                  </div>
                </div>
                <div className={classNames('field')}>
                  <div className={styles.isDuplicateWrapper}>
                    <input
                      className="is-checkradio is-medium"
                      id="globally"
                      type="radio"
                      name="globally"
                      checked={this.state.duplicateForm.globally}
                      onChange={() =>
                        this.setState({
                          duplicateForm: {
                            ...this.state.duplicateForm,
                            mine: !this.state.duplicateForm.mine,
                            globally: !this.state.duplicateForm.globally
                          }
                        })
                      }
                    />
                    <label className="radio" htmlFor="globally">
                      <p className={classNames('title', styles.isDuplicateWrapper__title)}>Globally</p>
                    </label>
                  </div>
                </div>
              </div> */}
            <div className={classNames('field', styles.field, styles.duplicates, styles.checkboxWrapper)}>
              <div className={classNames('first name')}>
                <div className={styles.checkboxWrapper}>
                  <div className={classNames('field', styles.field)}>
                    <label className="radio" htmlFor="first_name_dup">
                      <p className={classNames('title', styles.isDuplicateWrapper__title)}>First Name</p>
                    </label>
                    <input
                      className="is-checkradio is-medium"
                      id="first_name_dup"
                      type="checkbox"
                      name="first_name_dup"
                      checked={this.state.duplicateForm.fields.first_name}
                      onChange={() => {
                        let attr = {};
                        if (
                          this.state.duplicateForm.fields.first_name &&
                          !this.state.duplicateForm.fields.last_name &&
                          !this.state.duplicateForm.fields.address
                        ) {
                          attr = { last_name: true };
                        }
                        this.setState({
                          duplicateForm: {
                            ...this.state.duplicateForm,
                            fields: {
                              ...this.state.duplicateForm.fields,
                              first_name: !this.state.duplicateForm.fields.first_name,
                              ...attr
                            }
                          }
                        });
                      }}
                    />
                    <label htmlFor="first_name_dup" />
                  </div>
                </div>
              </div>
              <div className={classNames('last name')}>
                <div className={styles.checkboxWrapper}>
                  <div className={classNames('field', styles.field)}>
                    <label className="radio" htmlFor="last_name_dup">
                      <p className={classNames('title', styles.isDuplicateWrapper__title)}>Last Name</p>
                    </label>
                    <input
                      className="is-checkradio is-medium"
                      id="last_name_dup"
                      type="checkbox"
                      name="last_name_dup"
                      checked={this.state.duplicateForm.fields.last_name}
                      onChange={() => {
                        let attr = {};
                        if (
                          !this.state.duplicateForm.fields.first_name &&
                          this.state.duplicateForm.fields.last_name &&
                          !this.state.duplicateForm.fields.address
                        ) {
                          attr = { first_name: true };
                        }
                        this.setState({
                          duplicateForm: {
                            ...this.state.duplicateForm,
                            fields: {
                              ...this.state.duplicateForm.fields,
                              last_name: !this.state.duplicateForm.fields.last_name,
                              ...attr
                            }
                          }
                        });
                      }}
                    />
                    <label htmlFor="last_name_dup" />
                  </div>
                </div>
              </div>
              <div className={classNames('addressline')}>
                <div className={styles.checkboxWrapper}>
                  <div className={classNames('field', styles.field)}>
                    <label className="radio" htmlFor="address_dup">
                      <p className={classNames('title', styles.isDuplicateWrapper__title)}>Address Line</p>
                    </label>
                    <input
                      autoComplete="new-password"
                      className="is-checkradio is-medium"
                      id="address_dup"
                      type="checkbox"
                      name="address_dup"
                      checked={this.state.duplicateForm.fields.address}
                      onChange={() => {
                        let attr = {};
                        if (
                          !this.state.duplicateForm.fields.first_name &&
                          !this.state.duplicateForm.fields.last_name &&
                          this.state.duplicateForm.fields.address
                        ) {
                          attr = { first_name: true };
                        }
                        this.setState({
                          duplicateForm: {
                            ...this.state.duplicateForm,
                            fields: {
                              ...this.state.duplicateForm.fields,
                              address: !this.state.duplicateForm.fields.address,
                              ...attr
                            }
                          }
                        });
                      }}
                    />
                    <label htmlFor="address_dup" />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </form>
    );
  }

  get searchContent() {
    return (
      <div className={styles.searchContent}>
        {this.state.searches.length ? (
          <div className="advanced-wrapper">
            <ActiveBadgeList activeOptions={this.state.searches}>
              {option => (
                <ActiveBadgeItem
                  activeOption={option}
                  enable={() => this.setDisabled(option?.id)}
                  remove={() => this.removeSearchItem(option?.id)}
                >
                  <ActiveBadgeContent
                    label={'Field name include'}
                    withColon={true}
                    content={this.removeSpace(option.value).replace('.', '')}
                    isDisabled={option?.disabled}
                  />
                </ActiveBadgeItem>
              )}
            </ActiveBadgeList>
            {this.state.searches.length > 0 && (
              <p onClick={this.clearSearch} className="clear-search" data-tooltip="Clear All">
                <i className="far fa-trash-alt" />
              </p>
            )}
          </div>
        ) : null}
        <div className="search-wrapper">
          <div className="control has-icons-left">
            <input
              ref={this.search}
              name="search"
              className="input is-large"
              type="search"
              placeholder="Type to find the search criteria"
              autoComplete="off"
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  this.onSearch(e);
                }
              }}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-search" />
            </span>
          </div>
        </div>
      </div>
    );
  }

  setDisabled = id => {
    const searches = this.state.searches.map(el => {
      if (el.id === id) {
        el.disabled = !el.disabled;
        return el;
      } else {
        return el;
      }
    });
    setSessionItem('searches', JSON.stringify(searches));
    this.setState({ searches }, () => this.performSearch());
  };

  removeSearchItem = id => {
    const searches = this.state.searches.filter(el => el.id !== id);
    setSessionItem('searches', JSON.stringify(searches));
    this.setState({ searches }, () => this.performSearch());
  };

  clearSearch = () => {
    removeSessionItem('searches');
    removeSessionItem('advancedSearchActiveOptions');
    this.setState({ searches: [] }, () => this.performSearch());
  };

  onSearch = e => {
    const value = e.target.value.toLowerCase();
    if (value && !this.state.searches.find(el => el.value === value)) {
      const searches = [...this.state.searches, { disabled: false, value, id: random() }];
      setSessionItem('searches', JSON.stringify(searches));
      this.setState({ searches }, () => this.performSearch());
      e.target.value = '';
    }
  };

  performSearch = () => {
    const enabledSearches = this.state.searches.filter(el => !el.disabled);
    const searchLabels = ADVANCED_SEARCH_FIELDS(this.props.currentUser).map(el => el.label.toLowerCase());
    const searchedFields = enabledSearches.map(el => el.value.trim().replace(/\s+/g, ' '));
    const searched = searchLabels.filter(el => {
      return searchedFields.find(field => el.includes(field));
    });
    const classes = searched.map(el => '.' + el.replace(/[^A-Za-z]/g, '')).join(', ');
    this.queryElements('.field', 'none');
    if (classes.length > 0) {
      this.queryElements(classes, 'inherit');
    } else {
      this.queryElements('.field', 'inherit');
    }
  };

  removeSpace = value => {
    return value.trim().replace(' ', ', .');
  };

  queryElements = (query, style) => {
    Array.from(document.querySelectorAll(query)).forEach(el => {
      el.style.display = style;
    });
  };

  addMultipleValue = field => {
    let valueFromState = this.state[field];
    let value = this.value(field);
    let type = this.type(field);
    const isValueGood = value && !valueFromState.find(el => el.type === type)?.value.find(el => el === value);
    if (!isValueGood) return;

    const isTypeExist = valueFromState.find(el => el.type === type);
    if (isTypeExist) {
      valueFromState = valueFromState.map(el => {
        if (el.type === type) return { ...el, value: [...el.value, value] };
        return el;
      });
    } else {
      valueFromState.push({ type, value: [value] });
    }
    this.setState({ [field]: valueFromState });

    let changedObject = this.state.advancedSearchForm.find(el => el.field === field);
    changedObject.value = '';
    this.setForm(changedObject);
  };

  removeMultipleValues = field => {
    this.setState({ [field]: [] });
  };

  removeMultipleValue = (field, type, value) => {
    let valueFromState = this.state[field];

    valueFromState = valueFromState.map(el => {
      if (el.type === type) return { ...el, value: [...el.value.filter(val => val !== value)] };
      return el;
    });
    valueFromState = valueFromState.filter(el => el.value.length > 0);

    this.setState({ [field]: valueFromState });
  };

  onSubmit = async (e, callback) => {
    const normalisedArray = this.state.advancedSearchForm
      .map(el => {
        const { field, type, value } = el;
        if (type === 'empty' || type === 'not_empty' || type === 'none') return { ...el, value: 'true' };
        if (field === 'zip_code') {
          if (!(type === 'empty' || type === 'not_empty') && this.state.zip_code?.length > 0) {
            return null;
          } else {
            return { ...el, type, value: '' };
          }
        }
        if (field === 'ba_zip_code') {
          if (!(type === 'empty' || type === 'not_empty') && this.state.ba_zip_code?.length > 0) {
            return null;
          } else {
            return { ...el, type, value: '' };
          }
        }
        if (field === 'home_address_zip_code') {
          if (!(type === 'empty' || type === 'not_empty') && this.state.home_address_zip_code?.length > 0) {
            return null;
          } else {
            return { ...el, type, value: '' };
          }
        }
        if (field === 'tobacco') {
          if (type === 'include') {
            return { ...el, type: 'include', value: Array.isArray(value) ? value.map(option => option.value) : '' };
          } else if (type === 'exclude') {
            return { ...el, type: 'exclude', value: Array.isArray(value) ? value.map(option => option.value) : '' };
          }
        }

        if (this.isAllCarriersSelectedFor('include')) {
          if (field === 'data_capture_carrier') {
            if (type === 'include') {
              return {
                ...el,
                type: 'include',
                value: this.validOptions(this.state.carriers).map(option => option.value)
              };
            } else {
              return {
                ...el,
                type: 'exclude',
                value: []
              };
            }
          }
        } else if (this.isAllCarriersSelectedFor('exclude')) {
          if (field === 'data_capture_carrier') {
            if (type === 'exclude') {
              return {
                ...el,
                type: 'exclude',
                value: this.validOptions(this.state.carriers).map(option => option.value)
              };
            } else {
              return {
                ...el,
                type: 'include',
                value: []
              };
            }
          }
        }

        if (field.includes('writing_agent_1_number')) {
          if (this.state.writing_agent_1_number?.length > 0) {
            return null;
          } else {
            return { ...el, type: 'include', value: '' };
          }
        }
        if (field.includes('writing_agent_2_number')) {
          if (this.state.writing_agent_2_number?.length > 0) {
            return null;
          } else {
            return { ...el, type: 'include', value: '' };
          }
        }
        if (field.includes('writing_agent_3_number')) {
          if (this.state.writing_agent_3_number?.length > 0) {
            return null;
          } else {
            return { ...el, type: 'include', value: '' };
          }
        }
        if (field.includes('policy_carrier') && value && value.length > 0) {
          return {
            field,
            type,
            value: value.toString().replace(/,\s+/g, ',').split(',')
          };
        } else if (this.checkIncludesField(el)) {
          return {
            field,
            type,
            value: value.map(el => el.value)
          };
        } else if (field.includes('main_or_branch') && !!value) {
          return {
            field,
            type,
            value: value?.map(el => el.value) || []
          };
        } else if (field.includes('commissions') && !!value) {
          return {
            field,
            type,
            value: value?.map(el => el.value) || []
          };
        } else if (field.includes('aml') && !!value) {
          return {
            field,
            type,
            value: value?.map(el => el.value) || []
          };
        } else {
          return el;
        }
      })
      .filter(el => el);
    if (!normalisedArray.find(el => el.field === 'zip_code'))
      this.state.zip_code.forEach(el => {
        const { value, type } = el;
        normalisedArray.push({
          field: 'zip_code',
          type,
          value
        });
      });
    if (!normalisedArray.find(el => el.field === 'ba_zip_code'))
      this.state.ba_zip_code.forEach(el => {
        const { value, type } = el;
        normalisedArray.push({
          field: 'ba_zip_code',
          type,
          value
        });
      });
    if (!normalisedArray.find(el => el.field === 'home_address_zip_code'))
      this.state.home_address_zip_code.forEach(el => {
        const { value, type } = el;
        normalisedArray.push({
          field: 'home_address_zip_code',
          type,
          value
        });
      });
    if (!normalisedArray.find(el => el.field === 'writing_agent_1_number'))
      this.state.writing_agent_1_number.forEach(el => {
        const { value, type } = el;
        normalisedArray.push({
          field: 'writing_agent_1_number',
          type,
          value
        });
      });
    if (!normalisedArray.find(el => el.field === 'writing_agent_2_number'))
      this.state.writing_agent_2_number.forEach(el => {
        const { value, type } = el;
        normalisedArray.push({
          field: 'writing_agent_2_number',
          type,
          value
        });
      });
    if (!normalisedArray.find(el => el.field === 'writing_agent_3_number'))
      this.state.writing_agent_3_number.forEach(el => {
        const { value, type } = el;
        normalisedArray.push({
          field: 'writing_agent_3_number',
          type,
          value
        });
      });

    setSessionItem(
      LOCAL_STORAGE_SEARCH_KEY,
      JSON.stringify(normalisedArray.filter(el => (Array.isArray(el.value) ? el.value.length : el.value)))
    );
    setSessionItem(LOCAL_STORAGE_DUPLICATE_KEY, JSON.stringify(this.state.duplicateForm));
    removeSessionItem('advancedSearchActiveOptions');
    callback();
    await this.props.clearContacts();
    Router.push('/contacts');
  };

  render() {
    return (
      <Wrapper>
        <div className={styles.advancedSearch}>
          <h5>Advanced Search</h5>
          {this.searchContent}
          <div className={styles.content}>
            <form autoComplete="off" name="advancedSearchForm">
              <div className={classNames('field contactid', styles.field)}>
                <div className="contact_id">
                  <label htmlFor="contact_id">
                    <p className={classNames('title', styles.title)}>Contact ID</p>
                  </label>
                  <div className={styles.selectFields}>
                    <input
                      disabled={this.type('number') === 'empty' || this.type('number') === 'not_empty'}
                      name="number"
                      className="input is-large"
                      type="text"
                      maxLength="999"
                      placeholder="Contact ID"
                      value={this.value('number')}
                      onChange={this.changeBaseInput}
                    />
                    <Select
                      name="number"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={TYPE_OPTIONS.find(el => el.value === this.type('number'))}
                      options={TYPE_OPTIONS}
                      onChange={this.changeType}
                    />
                  </div>
                </div>
              </div>
              <div
                className={classNames('field owner', styles.field, styles.customIncludes, styles.customOwnerExclude)}
              >
                <div className="user_id">
                  <label htmlFor="user_id">
                    <p className={classNames('title', styles.title)}>Owner</p>
                  </label>
                  <div className={styles.selectFields}>
                    <AsyncSelect
                      instanceId={ownerId}
                      classNamePrefix="custom-select"
                      className={classNames('custom-select', styles.customSelectOwner)}
                      cacheOptions
                      isClearable
                      name="user_id"
                      placeholder="Owner"
                      value={
                        this.state.exclude_owners
                          ? this.valueIncludeExclude('user_id', 'exclude')
                          : this.valueIncludeExclude('user_id', 'include')
                      }
                      onChange={e => this.changeSelect(e, 'user_id', this.state.exclude_owners ? 'exclude' : 'include')}
                      defaultOptions
                      isMulti
                      loadOptions={this.loadOwners}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.checkboxWrapper}>
                <div className={classNames('field exclude', styles.field, styles.fullWidth)}>
                  <label htmlFor="user_id">
                    <p className={classNames('title', styles.title)}>Exclude selected Owner(s)</p>
                  </label>
                  <input
                    id="user_id"
                    type="checkbox"
                    name="user_id"
                    className="is-checkradio is-medium"
                    onChange={this.excludeOwners}
                    checked={this.state.exclude_owners}
                  />
                  <label htmlFor="user_id" />
                </div>
              </div>
              {config().AFFINITY && (
                <>
                  <div className={classNames('field affinity', styles.field, styles.customIncludes)}>
                    <div className="affinity">
                      <label htmlFor="affinity">
                        <p className={classNames('title', styles.title)}>Affinity</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="affinity"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('affinity', 'include')}
                            options={this.validOptions(AFFINITY, this.valueIncludeExclude('affinity', 'exclude'))}
                            onChange={e => this.changeSelect(e, 'affinity', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="affinity"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('affinity', 'exclude')}
                            options={this.validOptions(AFFINITY, this.valueIncludeExclude('affinity', 'include'))}
                            onChange={e => this.changeSelect(e, 'affinity', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field affinityname', styles.field)}>
                    <div className="affinity_name">
                      <label htmlFor="affinity_name">
                        <p className={classNames('title', styles.title)}>Affinity Name</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          disabled={
                            this.type('affinity_name') === 'empty' || this.type('affinity_name') === 'not_empty'
                          }
                          name="affinity_name"
                          className="input is-large"
                          type="text"
                          placeholder="Affinity Name"
                          value={this.value('affinity_name')}
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="affinity_name"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('affinity_name'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field affinitycampaign', styles.field)}>
                    <div className="affinity_campaign">
                      <label htmlFor="affinity_campaign">
                        <p className={classNames('title', styles.title)}>Affinity Campaign</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          disabled={
                            this.type('affinity_campaign') === 'empty' || this.type('affinity_campaign') === 'not_empty'
                          }
                          name="affinity_campaign"
                          className="input is-large"
                          type="text"
                          placeholder="Affinity Campaign"
                          value={this.value('affinity_campaign')}
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="affinity_campaign"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('affinity_campaign'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {config().CREDIT_UNION && (
                <div className={classNames('field creditunion', styles.field)}>
                  <div className="credit_union">
                    <label htmlFor="credit_union">
                      <p className={classNames('title', styles.title)}>Credit Union</p>
                    </label>
                    <div className={styles.selectFields}>
                      <input
                        maxLength="999"
                        disabled={this.type('credit_union') === 'empty' || this.type('credit_union') === 'not_empty'}
                        name="credit_union"
                        className="input is-large"
                        type="text"
                        placeholder="Credit Union"
                        value={this.value('credit_union')}
                        onChange={this.changeBaseInput}
                      />
                      <Select
                        name="credit_union"
                        className={classNames('custom-select', styles.customSelect)}
                        classNamePrefix="custom-select"
                        value={TYPE_OPTIONS.find(el => el.value === this.type('credit_union'))}
                        options={TYPE_OPTIONS}
                        onChange={this.changeType}
                      />
                    </div>
                  </div>
                </div>
              )}
              <AdvancedSearchItem
                label="First Name"
                param="first_name"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
              />
              <AdvancedSearchItem
                label="Last Name"
                param="last_name"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
              />
              {(this.props.currentUser.c_1 || this.props.currentUser.aris) && (
                <>
                  <div className={classNames('field middlename', styles.field)}>
                    <div className="middle_name">
                      <label htmlFor="middle_name">
                        <p className={classNames('title', styles.title)}>Middle Name</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          disabled={this.type('middle_name') === 'empty' || this.type('middle_name') === 'not_empty'}
                          name="middle_name"
                          className="input is-large"
                          type="text"
                          placeholder="Middle Name"
                          value={this.value('middle_name')}
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="middle_name"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('middle_name'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field nickname', styles.field)}>
                    <div className="nick_name">
                      <label htmlFor="nick_name">
                        <p className={classNames('title', styles.title)}>
                          {this.props.currentUser.aris ? 'Suffix' : 'Nick Name'}
                        </p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          disabled={this.type('nick_name') === 'empty' || this.type('nick_name') === 'not_empty'}
                          name="nick_name"
                          className="input is-large"
                          type="text"
                          placeholder={this.props.currentUser.aris ? 'Suffix' : 'Nick Name'}
                          value={this.value('nick_name')}
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="nick_name"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('nick_name'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <AdvancedSearchItem
                label="Address Line"
                param="address"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
              />
              {this.isRmCIA && (
                <AdvancedSearchItem
                  label="Home Address Line"
                  param="home_address_line_1"
                  value={this.value}
                  type={this.type}
                  changeType={this.changeType}
                  changeBaseInput={this.changeBaseInput}
                  submitOnEnter={this.submitOnEnter}
                  options={TYPE_OPTIONS}
                />
              )}
              <AdvancedSearchItem
                label="Address Line 2"
                param="address_2"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
              />
              {this.isRmCIA && (
                <AdvancedSearchItem
                  label="Home Address Line 2"
                  param="home_address_line_2"
                  value={this.value}
                  type={this.type}
                  changeType={this.changeType}
                  changeBaseInput={this.changeBaseInput}
                  submitOnEnter={this.submitOnEnter}
                  options={TYPE_OPTIONS}
                />
              )}
              <AdvancedSearchItem
                label="State"
                param="state"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
              />
              <AdvancedSearchItem
                label="City"
                param="city"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
              />
              <div className={classNames('field zipcode', styles.field)}>
                <div className="zip_code">
                  <label htmlFor="zip_code">
                    <p className={classNames('title', styles.title)}>Zip Code</p>
                  </label>
                  <div className={styles.selectFields}>
                    <input
                      id="zip_code"
                      name="zip_code"
                      disabled={this.type('zip_code') === 'empty' || this.type('zip_code') === 'not_empty'}
                      placeholder="Zip Code"
                      maxLength={10}
                      className="input is-large"
                      onChange={e => this.changeBaseInput(e, { withZipCodeFormat: true })}
                      value={this.value('zip_code')}
                    />
                    <Select
                      name="zip_code"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={TYPE_OPTIONS.find(el => el.value === this.type('zip_code'))}
                      options={TYPE_OPTIONS}
                      onChange={(changedObj, e) => {
                        this.changeType(changedObj, e);
                        if (changedObj.value === 'empty' || changedObj.value === 'not_empty')
                          this.removeMultipleValues('zip_code');
                      }}
                    />
                    <button
                      type="button"
                      disabled={this.type('zip_code') === 'empty' || this.type('zip_code') === 'not_empty'}
                      onClick={() => this.addMultipleValue('zip_code')}
                      className={classNames('button is-large is-secondary')}
                    >
                      + Add
                    </button>
                    <button
                      type="button"
                      disabled={this.type('zip_code') === 'empty' || this.type('zip_code') === 'not_empty'}
                      onClick={() => this.removeMultipleValues('zip_code')}
                      className={classNames('button is-large is-secondary')}
                    >
                      Remove All
                    </button>
                  </div>
                  {this.state.zip_code.map(type => {
                    return (
                      <div key={type.type}>
                        <p>
                          {TYPE_OPTIONS.find(option => option.value === type.type).label}
                          {' :'}
                        </p>
                        <div className={styles.labelWrapper}>
                          {type?.value?.map(value => (
                            <p
                              key={type.type + value}
                              onClick={e => e.stopPropagation()}
                              style={{ backgroundColor: 'grey' }}
                              className={styles.labeled}
                            >
                              {value}
                              <i
                                onClick={() => this.removeMultipleValue('zip_code', type.type, value)}
                                className="fas fa-times"
                              />
                            </p>
                          ))}{' '}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <AdvancedSearchItem
                label="County"
                param="county"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
              />
              <AdvancedSearchItem
                label={this.props.currentUser.c_1 ? 'Primary Phone' : 'Phone'}
                param="phone"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
              />
              <AdvancedSearchItem
                label={this.props.currentUser.c_1 || this.props.currentUser.aris ? 'Alt 1' : 'Mobile'}
                param="cell_phone"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
              />
              {config(this.props.currentUser).SMS_FEATURE && !config(this.props.currentUser).SMS_DISABLED && (
                <div className={styles.checkboxWrapper}>
                  <div className={classNames('field verifiedmobile', styles.field)}>
                    <label htmlFor="verified_mobile">
                      <p className={classNames('title', styles.title)}>Verified Mobile</p>
                    </label>
                    <input
                      id="verified_mobile"
                      type="checkbox"
                      name="verified_mobile"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeBoolean('verified_mobile')}
                      checked={this.value('verified_mobile')}
                    />
                    <label htmlFor="verified_mobile" />
                  </div>
                  <div className={classNames('field verifiedlandline', styles.field)}>
                    <label htmlFor="verified_landline">
                      <p className={classNames('title', styles.title)}>Verified Landline</p>
                    </label>
                    <input
                      id="verified_landline"
                      type="checkbox"
                      name="verified_landline"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeBoolean('verified_landline')}
                      checked={this.value('verified_landline')}
                    />
                    <label htmlFor="verified_landline" />
                  </div>
                </div>
              )}
              <AdvancedSearchItem
                label={'Verified Mobile'}
                param="verified_phone"
                classes="verifiedmobile"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
              />
              <div
                className={classNames(
                  `field ${
                    config().MYASBFINANCIAL
                      ? 'phone'
                      : this.props.currentUser.c_1 || this.props.currentUser.aris
                      ? 'alt'
                      : 'altphone'
                  }`,
                  styles.field
                )}
              >
                <div className="alt2">
                  <label htmlFor="alt2">
                    <p className={classNames('title', styles.title)}>
                      {config().MYASBFINANCIAL
                        ? 'Phone 3'
                        : this.props.currentUser.c_1 || this.props.currentUser.aris
                        ? 'Alt 2'
                        : 'Alt Phone'}
                    </p>
                  </label>
                  <div className={styles.selectFields}>
                    <input
                      maxLength="999"
                      id="alt2"
                      type="text"
                      name="alt2"
                      disabled={this.type('alt2') === 'empty' || this.type('alt2') === 'not_empty'}
                      placeholder={
                        config().MYASBFINANCIAL
                          ? 'Phone 3'
                          : this.props.currentUser.c_1 || this.props.currentUser.aris
                          ? 'Alt 2'
                          : 'Alt Phone'
                      }
                      className="input is-large"
                      onChange={this.changeBaseInput}
                      value={this.value('alt2')}
                    />
                    <Select
                      name="alt2"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={TYPE_OPTIONS.find(el => el.value === this.type('alt2'))}
                      options={TYPE_OPTIONS}
                      onChange={this.changeType}
                    />
                  </div>
                </div>
              </div>
              {config().MYASBFINANCIAL && (
                <div className={classNames('field phone', styles.field)}>
                  <div className="alt3">
                    <label htmlFor="alt3">
                      <p className={classNames('title', styles.title)}>Phone 4</p>
                    </label>
                    <div className={styles.selectFields}>
                      <input
                        maxLength="999"
                        id="alt3"
                        type="text"
                        name="alt3"
                        disabled={this.type('alt3') === 'empty' || this.type('alt3') === 'not_empty'}
                        placeholder="Phone 4"
                        className="input is-large"
                        onChange={this.changeBaseInput}
                        value={this.value('alt3')}
                      />
                      <Select
                        name="alt3"
                        className={classNames('custom-select', styles.customSelect)}
                        classNamePrefix="custom-select"
                        value={TYPE_OPTIONS.find(el => el.value === this.type('alt3'))}
                        options={TYPE_OPTIONS}
                        onChange={this.changeType}
                      />
                    </div>
                  </div>
                </div>
              )}
              <AdvancedSearchItem
                label="Email"
                param="email"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
              />
              {this.isRmCIA && (
                <AdvancedSearchItem
                  label="Personal Email"
                  param="personal_email"
                  value={this.value}
                  type={this.type}
                  changeType={this.changeType}
                  changeBaseInput={this.changeBaseInput}
                  submitOnEnter={this.submitOnEnter}
                  options={TYPE_OPTIONS}
                />
              )}
              {config().REFERRED_BY && (
                <AdvancedSearchItem
                  label="Referred By"
                  param="referred_by"
                  value={this.value}
                  type={this.type}
                  changeType={this.changeType}
                  changeBaseInput={this.changeBaseInput}
                  submitOnEnter={this.submitOnEnter}
                  options={TYPE_OPTIONS}
                />
              )}
              {config().COMPANY_FIELD && (
                <div className={classNames('field company', styles.block, styles.field)}>
                  <div className="company">
                    <label htmlFor="company">
                      <p className={classNames('title', styles.title)}>Company</p>
                    </label>
                    <div className={styles.selectFields}>
                      <input
                        maxLength="999"
                        name="company"
                        disabled={this.type('company') === 'empty' || this.type('company') === 'not_empty'}
                        className="input is-large"
                        type="text"
                        placeholder="Company"
                        value={this.value('company')}
                        onChange={this.changeBaseInput}
                      />
                      <Select
                        name="company"
                        className={classNames('custom-select', styles.customSelect)}
                        classNamePrefix="custom-select"
                        value={TYPE_OPTIONS.find(el => el.value === this.type('company'))}
                        options={TYPE_OPTIONS}
                        onChange={this.changeType}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={classNames('field status', styles.field, styles.customIncludes)}>
                <div className="status">
                  <label htmlFor="status">
                    <p className={classNames('title', styles.title)}>Status</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="status"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('status_id', 'include')}
                        options={this.validOptions(
                          this.props.statuses,
                          this.valueIncludeExclude('status_id', 'exclude')
                        )}
                        onChange={e => this.changeSelect(e, 'status_id', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="status"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to exclude..."
                        value={this.valueIncludeExclude('status_id', 'exclude')}
                        options={this.validOptions(
                          this.props.statuses,
                          this.valueIncludeExclude('status_id', 'include')
                        )}
                        onChange={e => this.changeSelect(e, 'status_id', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames('field source', styles.field, styles.customIncludes)}>
                <div className="source">
                  <label htmlFor="source">
                    <p className={classNames('title', styles.title)}>Source</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="source"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('source_id', 'include')}
                        options={this.validOptions(
                          this.props.sources,
                          this.valueIncludeExclude('source_id', 'exclude')
                        )}
                        onChange={e => this.changeSelect(e, 'source_id', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="source"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type` to exclude..."
                        value={this.valueIncludeExclude('source_id', 'exclude')}
                        options={this.validOptions(
                          this.props.sources,
                          this.valueIncludeExclude('source_id', 'include')
                        )}
                        onChange={e => this.changeSelect(e, 'source_id', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames('field originalsource', styles.field, styles.customIncludes)}>
                <div className="original_source">
                  <label htmlFor="original_source">
                    <p className={classNames('title', styles.title)}>Original Source</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="original_source"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('original_source_id', 'include')}
                        options={this.validOptions(
                          this.props.sources,
                          this.valueIncludeExclude('original_source_id', 'exclude')
                        )}
                        onChange={e => this.changeSelect(e, 'original_source_id', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="original_source"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type` to exclude..."
                        value={this.valueIncludeExclude('original_source_id', 'exclude')}
                        options={this.validOptions(
                          this.props.sources,
                          this.valueIncludeExclude('original_source_id', 'include')
                        )}
                        onChange={e => this.changeSelect(e, 'original_source_id', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames('field color', styles.field, styles.customIncludes)}>
                <div className="color">
                  <label htmlFor="color">
                    <p className={classNames('title', styles.title)}>Color</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="color"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('color', 'include')}
                        options={this.validOptions(COLORS_OPTIONS, this.valueIncludeExclude('color', 'exclude'))}
                        onChange={e => this.changeSelect(e, 'color', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="color"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to exclude..."
                        value={this.valueIncludeExclude('color', 'exclude')}
                        options={this.validOptions(COLORS_OPTIONS, this.valueIncludeExclude('color', 'include'))}
                        onChange={e => this.changeSelect(e, 'color', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames('field dob', styles.field)}>
                <div className="dob_date">
                  <label htmlFor="dob_date">
                    <p className={classNames('title', styles.title)}>DOB</p>
                  </label>
                  <div className={styles.dateFields}>
                    {this.type('dob_date') !== 'range' && (
                      <DatePicker
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        closeOnScroll={true}
                        id="dob_date"
                        maxDate={new Date()}
                        selected={parseDateUTC(this.value('dob_date'))}
                        onChange={e => this.changeDate(e, 'dob_date')}
                        name="dob_date"
                        placeholderText="mm/dd/yyyy"
                        dateFormat="MM/dd/yyyy"
                        className={classNames('input is-large', styles.dateStart)}
                        disabled={this.type('dob_date') === 'all' || this.type('dob_date') === 'none'}
                      />
                    )}

                    {this.type('dob_date') === 'range' && (
                      <>
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          selected={parseDateUTC(this.valueRangeStart('dob_date'))}
                          onChange={e => this.changeDateRangeStart(e, 'dob_date')}
                          name="dob_date"
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateStart)}
                        />
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          minDate={parseDateUTC(this.valueRangeStart('dob_date'))}
                          selected={parseDateUTC(this.valueRangeEnd('dob_date'))}
                          onChange={e => this.changeDateRangeEnd(e, 'dob_date')}
                          name="dob_date"
                          disabled={!this.valueRangeStart('dob_date')}
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateEnd)}
                        />
                      </>
                    )}
                    <Select
                      name="dob_date"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={DATE_OPTIONS.find(el => el.value === this.type('dob_date'))}
                      options={DATE_OPTIONS}
                      onChange={this.changeDateType}
                    />
                  </div>
                </div>
              </div>
              {config().CAREINADVANCE && (
                <div className={classNames('field dateofdeath', styles.field)}>
                  <div className="date_of_death">
                    <label htmlFor="date_of_death">
                      <p className={classNames('title', styles.title)}>Date Of Death</p>
                    </label>
                    <div className={styles.dateFields}>
                      {this.type('date_of_death') !== 'range' && (
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          id="date_of_death"
                          maxDate={new Date()}
                          selected={parseDateUTC(this.value('date_of_death'))}
                          onChange={e => this.changeDate(e, 'date_of_death')}
                          name="date_of_death"
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateStart)}
                          disabled={this.type('date_of_death') === 'all' || this.type('date_of_death') === 'none'}
                        />
                      )}

                      {this.type('date_of_death') === 'range' && (
                        <>
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            closeOnScroll={true}
                            maxDate={new Date()}
                            selected={parseDateUTC(this.valueRangeStart('date_of_death'))}
                            onChange={e => this.changeDateRangeStart(e, 'date_of_death')}
                            name="date_of_death"
                            placeholderText="mm/dd/yyyy"
                            dateFormat="MM/dd/yyyy"
                            className={classNames('input is-large', styles.dateStart)}
                          />
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            closeOnScroll={true}
                            maxDate={new Date()}
                            minDate={parseDateUTC(this.valueRangeStart('date_of_death'))}
                            selected={parseDateUTC(this.valueRangeEnd('date_of_death'))}
                            onChange={e => this.changeDateRangeEnd(e, 'date_of_death')}
                            name="date_of_death"
                            disabled={!this.valueRangeStart('date_of_death')}
                            placeholderText="mm/dd/yyyy"
                            dateFormat="MM/dd/yyyy"
                            className={classNames('input is-large', styles.dateEnd)}
                          />
                        </>
                      )}
                      <Select
                        name="date_of_death"
                        className={classNames('custom-select', styles.customSelect)}
                        classNamePrefix="custom-select"
                        value={DATE_OPTIONS.find(el => el.value === this.type('date_of_death'))}
                        options={DATE_OPTIONS}
                        onChange={this.changeDateType}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={classNames('field birthdatemonth', styles.field, styles.customIncludes)}>
                <div className="dob_month">
                  <label htmlFor="dob_month">
                    <p className={classNames('title', styles.title)}>Birth Date Month</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="dob_month"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('dob_month', 'include')}
                        options={this.validOptions(MONTHS, this.valueIncludeExclude('dob_month', 'exclude'))}
                        onChange={e => this.changeSelect(e, 'dob_month', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="dob_month"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to exclude..."
                        value={this.valueIncludeExclude('dob_month', 'exclude')}
                        options={this.validOptions(MONTHS, this.valueIncludeExclude('dob_month', 'include'))}
                        onChange={e => this.changeSelect(e, 'dob_month', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames('field birthdateday', styles.field)}>
                <div className={classNames('dob_day')}>
                  <label htmlFor="dob_day">
                    <p className={classNames('title', styles.title)}>Birth Date Day</p>
                  </label>
                  <div className={classNames(styles.dateFields)}>
                    {this.type('dob_day') !== 'range' && (
                      <PatternFormat
                        className={classNames('input is-large', styles.dobDay, styles.dateStart)}
                        id="dob_day"
                        format="##/##"
                        placeholder="MM/DD"
                        isAllowed={validateDay}
                        mask={'_'}
                        name="dob_day"
                        value={this.value('dob_day')}
                        onChange={e => this.changeDate(e, 'dob_day')}
                        disabled={this.type('dob_day') === 'all' || this.type('dob_day') === 'none'}
                      />
                    )}
                    {this.type('dob_day') === 'range' && (
                      <>
                        <PatternFormat
                          className={classNames('input is-large', styles.dobDay, styles.dateStart)}
                          format="##/##"
                          placeholder="MM/DD"
                          mask={'_'}
                          isAllowed={validateDay}
                          name="dob_day"
                          value={this.valueRangeStart('dob_day')}
                          onChange={e => this.changeDateRangeStart(e, 'dob_day')}
                        />
                        <PatternFormat
                          className={classNames('input is-large', styles.dobDay, styles.dateEnd)}
                          format="##/##"
                          placeholder="MM/DD"
                          mask={'_'}
                          isAllowed={validateDay}
                          name="dob_day"
                          value={this.valueRangeEnd('dob_day')}
                          onChange={e => this.changeDateRangeEnd(e, 'dob_day')}
                          disabled={!this.valueRangeStart('dob_day')}
                        />
                      </>
                    )}
                    <Select
                      name="dob_day"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={DATE_OPTIONS.find(el => el.value === this.type('dob_day'))}
                      options={DATE_OPTIONS.slice(0, -3)}
                      onChange={this.changeDateType}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames('field gender', styles.field, styles.customIncludes)}>
                <div className="gender">
                  <label htmlFor="gender">
                    <p className={classNames('title', styles.title)}>Gender</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="gender"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('gender', 'include')}
                        options={this.validOptions(GENDERS, this.valueIncludeExclude('gender', 'exclude'))}
                        onChange={e => this.changeSelect(e, 'gender', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="gender"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to exclude..."
                        value={this.valueIncludeExclude('gender', 'exclude')}
                        options={this.validOptions(GENDERS, this.valueIncludeExclude('gender', 'include'))}
                        onChange={e => this.changeSelect(e, 'gender', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {config().CAREINADVANCE && (
                <>
                  <div className={classNames('field funeralhome', styles.field)}>
                    <div className="funeral_home">
                      <label htmlFor="funeral_home">
                        <p className={classNames('title', styles.title)}>Funeral Home Name</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          disabled={this.type('funeral_home') === 'empty' || this.type('funeral_home') === 'not_empty'}
                          name="funeral_home"
                          className="input is-large"
                          type="text"
                          placeholder="Funeral Home Name"
                          value={this.value('funeral_home')}
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="funeral_home"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('funeral_home'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field funeralhomeid', styles.field)}>
                    <div className="funeral_home_id">
                      <label htmlFor="funeral_home_id">
                        <p className={classNames('title', styles.title)}>Funeral Home ID</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          name="funeral_home_id"
                          disabled={
                            this.type('funeral_home_id') === 'empty' || this.type('funeral_home_id') === 'not_empty'
                          }
                          className="input is-large"
                          type="text"
                          placeholder="Funeral Home ID"
                          value={this.value('funeral_home_id')}
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="funeral_home_id"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('funeral_home_id'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field survivorfirstname', styles.field)}>
                    <div className="survivor_first_name">
                      <label htmlFor="survivor_first_name">
                        <p className={classNames('title', styles.title)}>Survivor First Name</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          disabled={
                            this.type('survivor_first_name') === 'empty' ||
                            this.type('survivor_first_name') === 'not_empty'
                          }
                          name="survivor_first_name"
                          className="input is-large"
                          type="text"
                          placeholder="Survivor First Name"
                          value={this.value('survivor_first_name')}
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="survivor_first_name"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('survivor_first_name'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field survivorlastname', styles.field)}>
                    <div className="survivor_last_name">
                      <label htmlFor="survivor_last_name">
                        <p className={classNames('title', styles.title)}>Survivor Last Name</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          name="survivor_last_name"
                          disabled={
                            this.type('survivor_last_name') === 'empty' ||
                            this.type('survivor_last_name') === 'not_empty'
                          }
                          className="input is-large"
                          type="text"
                          placeholder="Survivor Last Name"
                          value={this.value('survivor_last_name')}
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="survivor_last_name"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('survivor_last_name'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field survivormobilephone', styles.field)}>
                    <div className="mobile_phone">
                      <label htmlFor="mobile_phone">
                        <p className={classNames('title', styles.title)}>Survivor Mobile Phone</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          id="mobile_phone"
                          name="mobile_phone"
                          type="text"
                          disabled={this.type('mobile_phone') === 'empty' || this.type('mobile_phone') === 'not_empty'}
                          placeholder="Survivor Mobile Phone"
                          className="input is-large"
                          onChange={this.changeBaseInput}
                          value={this.value('mobile_phone')}
                        />
                        <Select
                          name="mobile_phone"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('mobile_phone'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field survivorrelationship', styles.field, styles.customIncludes)}>
                    <div className="survivor_relationship">
                      <label htmlFor="survivor_relationship">
                        <p className={classNames('title', styles.title)}>Survivor Relationship</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="survivor_relationship"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('survivor_relationship', 'include')}
                            options={this.validOptions(
                              RELATIONSHIP_OPTIONS,
                              this.valueIncludeExclude('survivor_relationship', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'survivor_relationship', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="survivor_relationship"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('survivor_relationship', 'exclude')}
                            options={this.validOptions(
                              RELATIONSHIP_OPTIONS,
                              this.valueIncludeExclude('survivor_relationship', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'survivor_relationship', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field associateddeceased', styles.field)}>
                    <div className="associated_deceased">
                      <label htmlFor="associated_deceased">
                        <p className={classNames('title', styles.title)}>Associated Deceased</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          disabled={
                            this.type('associated_deceased') === 'empty' ||
                            this.type('associated_deceased') === 'not_empty'
                          }
                          name="associated_deceased"
                          className="input is-large"
                          type="text"
                          placeholder="Associated Deceased"
                          value={this.value('associated_deceased')}
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="associated_deceased"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('associated_deceased'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {config().MYCSSAGENT && (
                <>
                  <div className={classNames('field disposition', styles.field, styles.customIncludes)}>
                    <div className="disposition">
                      <label htmlFor="disposition">
                        <p className={classNames('title', styles.title)}>Disposition</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="disposition"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('disposition', 'include')}
                            options={this.validOptions(
                              CONTACT_DISPOSITIONS,
                              this.valueIncludeExclude('disposition', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'disposition', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="disposition"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('disposition', 'exclude')}
                            options={this.validOptions(
                              CONTACT_DISPOSITIONS,
                              this.valueIncludeExclude('disposition', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'disposition', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <AdvancedSearchItem
                    label="Policy Holder ID"
                    param="policy_holder_id"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Medicare ID"
                    param="medicare_id"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="A Start"
                    param="a_start"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="B Start"
                    param="b_start"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Ref ID"
                    param="ref_id"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                </>
              )}
              <div className={classNames('field spousedob', styles.field)}>
                <div className="spouse_dob_date">
                  <label htmlFor="spouse_dob_date">
                    <p className={classNames('title', styles.title)}>Spouse DOB</p>
                  </label>
                  <div className={styles.dateFields}>
                    {this.type('spouse_dob_date') !== 'range' && (
                      <DatePicker
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        closeOnScroll={true}
                        id="spouse_dob_date"
                        maxDate={new Date()}
                        selected={parseDateUTC(this.value('spouse_dob_date'))}
                        onChange={e => this.changeDate(e, 'spouse_dob_date')}
                        name="spouse_dob_date"
                        placeholderText="mm/dd/yyyy"
                        dateFormat="MM/dd/yyyy"
                        className={classNames('input is-large', styles.dateStart)}
                        disabled={this.type('spouse_dob_date') === 'all' || this.type('spouse_dob_date') === 'none'}
                      />
                    )}

                    {this.type('spouse_dob_date') === 'range' && (
                      <>
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          selected={parseDateUTC(this.valueRangeStart('spouse_dob_date'))}
                          onChange={e => this.changeDateRangeStart(e, 'spouse_dob_date')}
                          name="spouse_dob_date"
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateStart)}
                        />
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          minDate={parseDateUTC(this.valueRangeStart('spouse_dob_date'))}
                          selected={parseDateUTC(this.valueRangeEnd('spouse_dob_date'))}
                          onChange={e => this.changeDateRangeEnd(e, 'spouse_dob_date')}
                          name="spouse_dob_date"
                          disabled={!this.valueRangeStart('spouse_dob_date')}
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateEnd)}
                        />
                      </>
                    )}
                    <Select
                      name="spouse_dob_date"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={DATE_OPTIONS.find(el => el.value === this.type('spouse_dob_date'))}
                      options={DATE_OPTIONS}
                      onChange={this.changeDateType}
                    />
                  </div>
                </div>
              </div>
              <AdvancedSearchItem
                label={this.props.currentUser.c_1 || config().SPOUSE ? 'Spouse First Name' : 'Spouse Name'}
                param="spouse_first_name"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
                placeholder={this.props.currentUser.c_1 || config().SPOUSE ? 'Spouse First Name' : 'Spouse'}
              />
              {(this.props.currentUser.c_1 || config().SPOUSE) && (
                <div className={classNames('field spousename', styles.field)}>
                  <div className="spouse">
                    <label htmlFor="spouse_last_name">
                      <p className={classNames('title', styles.title)}>Spouse Last Name</p>
                    </label>
                    <div className={styles.selectFields}>
                      <input
                        maxLength="999"
                        name="spouse_last_name"
                        disabled={
                          this.type('spouse_last_name') === 'empty' || this.type('spouse_last_name') === 'not_empty'
                        }
                        className="input is-large"
                        type="text"
                        placeholder="Spouse Last Name"
                        value={this.value('spouse_last_name')}
                        onChange={this.changeBaseInput}
                      />
                      <Select
                        name="spouse_last_name"
                        className={classNames('custom-select', styles.customSelect)}
                        classNamePrefix="custom-select"
                        value={TYPE_OPTIONS.find(el => el.value === this.type('spouse_last_name'))}
                        options={TYPE_OPTIONS}
                        onChange={this.changeType}
                      />
                    </div>
                  </div>
                </div>
              )}
              {config().ASBAGENTMN && (
                <div className={classNames('field spousephone', styles.field)}>
                  <div className="spouse_phone">
                    <label htmlFor="spouse_phone">
                      <p className={classNames('title', styles.title)}>Spouse Phone</p>
                    </label>
                    <div className={styles.selectFields}>
                      <input
                        maxLength="999"
                        id="spouse_phone"
                        type="text"
                        name="spouse_phone"
                        disabled={this.type('spouse_phone') === 'empty' || this.type('spouse_phone') === 'not_empty'}
                        placeholder="Spouse Phone"
                        className="input is-large"
                        onChange={this.changeBaseInput}
                        value={this.value('spouse_phone')}
                      />
                      <Select
                        name="spouse_phone"
                        className={classNames('custom-select', styles.customSelect)}
                        classNamePrefix="custom-select"
                        value={TYPE_OPTIONS.find(el => el.value === this.type('spouse_phone'))}
                        options={TYPE_OPTIONS}
                        onChange={this.changeType}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={classNames('field spousegender', styles.field, styles.customIncludes)}>
                <div className="spouse_gender">
                  <label htmlFor="spouse_gender">
                    <p className={classNames('title', styles.title)}>Spouse Gender</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="spouse_gender"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('spouse_gender', 'include')}
                        options={this.validOptions(GENDERS, this.valueIncludeExclude('spouse_gender', 'exclude'))}
                        onChange={e => this.changeSelect(e, 'spouse_gender', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="spouse_gender"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to exclude..."
                        value={this.valueIncludeExclude('spouse_gender', 'exclude')}
                        options={this.validOptions(GENDERS, this.valueIncludeExclude('spouse_gender', 'include'))}
                        onChange={e => this.changeSelect(e, 'spouse_gender', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {config().MYSIBAGENT && (
                <>
                  <AdvancedSearchItem
                    label="MrAge"
                    param="mr_age"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={AGE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="MrsAge"
                    param="mrs_age"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={AGE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="InsComp"
                    param="ins_comp"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItemDate
                    title="TMDate Created"
                    param="tm_date_created_at"
                    changeDateType={this.changeDateType}
                    changeDateRangeStart={this.changeDateRangeStart}
                    valueRangeStart={this.valueRangeStart}
                    type={this.type}
                    changeDate={this.changeDate}
                    value={this.value}
                    valueRangeEnd={this.valueRangeEnd}
                    changeDateRangeEnd={this.changeDateRangeEnd}
                  />
                  <div className={classNames('field tmid', styles.field, styles.customIncludes)}>
                    <div className="tm_uid">
                      <label htmlFor="tm_uid">
                        <p className={classNames('title', styles.title)}>TM ID</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="tm_uid"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('tm_uid', 'include')}
                            options={this.validOptions(TM_UIDS, this.valueIncludeExclude('tm_uid', 'exclude'))}
                            onChange={e => this.changeSelect(e, 'tm_uid', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="tm_uid"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('tm_uid', 'exclude')}
                            options={this.validOptions(TM_UIDS, this.valueIncludeExclude('tm_uid', 'include'))}
                            onChange={e => this.changeSelect(e, 'tm_uid', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className={classNames('field dateadded', styles.field)}>
                <div className="created_date">
                  <label htmlFor="created_date">
                    <p className={classNames('title', styles.title)}>Date Added</p>
                  </label>
                  <div className={styles.dateFields}>
                    {this.type('created_date') !== 'range' && (
                      <DatePicker
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        closeOnScroll={true}
                        id="created_date"
                        maxDate={new Date()}
                        selected={parseDateUTC(this.value('created_date'))}
                        onChange={e => this.changeDate(e, 'created_date')}
                        name="created_date"
                        placeholderText="mm/dd/yyyy"
                        dateFormat="MM/dd/yyyy"
                        className={classNames('input is-large', styles.dateStart)}
                        disabled={this.type('created_date') === 'all' || this.type('created_date') === 'none'}
                      />
                    )}

                    {this.type('created_date') === 'range' && (
                      <>
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          id="created_date"
                          maxDate={new Date()}
                          selected={parseDateUTC(this.valueRangeStart('created_date'))}
                          onChange={e => this.changeDateRangeStart(e, 'created_date')}
                          name="created_date"
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateStart)}
                        />
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          minDate={parseDateUTC(this.valueRangeStart('created_date'))}
                          selected={parseDateUTC(this.valueRangeEnd('created_date'))}
                          onChange={e => this.changeDateRangeEnd(e, 'created_date')}
                          name="created_date"
                          disabled={!this.valueRangeStart('created_date')}
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateEnd)}
                        />
                      </>
                    )}
                    <Select
                      name="created_date"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={DATE_OPTIONS.find(el => el.value === this.type('created_date'))}
                      options={DATE_OPTIONS.slice(0, -1)}
                      onChange={this.changeDateType}
                    />
                  </div>
                </div>
              </div>
              {!config().AEGLEADS_SYSTEM && (
                <div className={classNames('field followup', styles.field)}>
                  <div className="follow_up_date">
                    <label htmlFor="follow_up_date">
                      <p className={classNames('title', styles.title)}>Follow Up</p>
                    </label>
                    <div className={styles.dateFields}>
                      {this.type('follow_up_date') !== 'range' && (
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          id="follow_up_date"
                          minDate={this.type('follow_up_date') !== 'on' && new Date()}
                          selected={parseDateUTC(this.value('follow_up_date'))}
                          onChange={e => this.changeDate(e, 'follow_up_date')}
                          name="follow_up_date"
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateStart)}
                          disabled={this.type('follow_up_date') === 'all' || this.type('follow_up_date') === 'none'}
                        />
                      )}

                      {this.type('follow_up_date') === 'range' && (
                        <>
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            closeOnScroll={true}
                            selected={parseDateUTC(this.valueRangeStart('follow_up_date'))}
                            onChange={e => this.changeDateRangeStart(e, 'follow_up_date')}
                            name="notes_added_date"
                            placeholderText="mm/dd/yyyy"
                            dateFormat="MM/dd/yyyy"
                            className={classNames('input is-large', styles.dateStart)}
                          />
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            closeOnScroll={true}
                            minDate={parseDateUTC(this.valueRangeStart('follow_up_date'))}
                            selected={parseDateUTC(this.valueRangeEnd('follow_up_date'))}
                            onChange={e => this.changeDateRangeEnd(e, 'follow_up_date')}
                            name="notes_added_date"
                            disabled={!this.valueRangeStart('follow_up_date')}
                            placeholderText="mm/dd/yyyy"
                            dateFormat="MM/dd/yyyy"
                            className={classNames('input is-large', styles.dateEnd)}
                          />
                        </>
                      )}
                      <Select
                        name="follow_up_date"
                        className={classNames('custom-select', styles.customSelect)}
                        classNamePrefix="custom-select"
                        value={DATE_OPTIONS.find(el => el.value === this.type('follow_up_date'))}
                        options={DATE_OPTIONS}
                        onChange={this.changeDateType}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={classNames('field dateupdate', styles.field)}>
                <div className="updated_date">
                  <label htmlFor="updated_date">
                    <p className={classNames('title', styles.title)}>Date Update</p>
                  </label>
                  <div className={styles.dateFields}>
                    {this.type('updated_date') !== 'range' && (
                      <DatePicker
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        closeOnScroll={true}
                        id="updated_date"
                        maxDate={new Date()}
                        selected={parseDateUTC(this.value('updated_date'))}
                        onChange={e => this.changeDate(e, 'updated_date')}
                        name="updated_date"
                        placeholderText="mm/dd/yyyy"
                        dateFormat="MM/dd/yyyy"
                        className={classNames('input is-large', styles.dateStart)}
                        disabled={this.type('updated_date') === 'all' || this.type('updated_date') === 'none'}
                      />
                    )}

                    {this.type('updated_date') === 'range' && (
                      <>
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          selected={parseDateUTC(this.valueRangeStart('updated_date'))}
                          onChange={e => this.changeDateRangeStart(e, 'updated_date')}
                          name="updated_date"
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateStart)}
                        />
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          minDate={parseDateUTC(this.valueRangeStart('updated_date'))}
                          selected={parseDateUTC(this.valueRangeEnd('updated_date'))}
                          onChange={e => this.changeDateRangeEnd(e, 'updated_date')}
                          name="updated_date"
                          disabled={!this.valueRangeStart('updated_date')}
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateEnd)}
                        />
                      </>
                    )}
                    <Select
                      name="updated_date"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={DATE_OPTIONS.find(el => el.value === this.type('updated_date'))}
                      options={DATE_OPTIONS.slice(0, -1)}
                      onChange={this.changeDateType}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames('field productssold', styles.field, styles.customIncludes)}>
                <div className="products_in_house">
                  <label htmlFor="products_in_house">
                    <p className={classNames('title', styles.title)}>Products Sold</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="products_in_house"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('products_in_house', 'include')}
                        options={this.validOptions(
                          CONTACT_PRODUCTS_IN_HOUSE,
                          this.valueIncludeExclude('products_in_house', 'exclude')
                        )}
                        onChange={e => this.changeSelect(e, 'products_in_house', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="products_in_house"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to exclude..."
                        value={this.valueIncludeExclude('products_in_house', 'exclude')}
                        options={this.validOptions(
                          CONTACT_PRODUCTS_IN_HOUSE,
                          this.valueIncludeExclude('products_in_house', 'include')
                        )}
                        onChange={e => this.changeSelect(e, 'products_in_house', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames('field datenoteadded', styles.field)}>
                <div className="notes_added_date">
                  <label htmlFor="notes_added_date">
                    <p className={classNames('title', styles.title)}>Date Note Added</p>
                  </label>
                  <div className={styles.dateFields}>
                    {this.type('notes_added_date') !== 'range' && (
                      <DatePicker
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        closeOnScroll={true}
                        id="notes_added_date"
                        maxDate={new Date()}
                        selected={parseDateUTC(this.value('notes_added_date'))}
                        onChange={e => this.changeDate(e, 'notes_added_date')}
                        name="notes_added_date"
                        placeholderText="mm/dd/yyyy"
                        dateFormat="MM/dd/yyyy"
                        className={classNames('input is-large', styles.dateStart)}
                        disabled={this.type('notes_added_date') === 'all' || this.type('notes_added_date') === 'none'}
                      />
                    )}

                    {this.type('notes_added_date') === 'range' && (
                      <>
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          selected={parseDateUTC(this.valueRangeStart('notes_added_date'))}
                          onChange={e => this.changeDateRangeStart(e, 'notes_added_date')}
                          name="notes_added_date"
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateStart)}
                        />
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          minDate={parseDateUTC(this.valueRangeStart('notes_added_date'))}
                          selected={parseDateUTC(this.valueRangeEnd('notes_added_date'))}
                          onChange={e => this.changeDateRangeEnd(e, 'notes_added_date')}
                          name="notes_added_date"
                          disabled={!this.valueRangeStart('notes_added_date')}
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateEnd)}
                        />
                      </>
                    )}
                    <Select
                      name="notes_added_date"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={DATE_OPTIONS.find(el => el.value === this.type('notes_added_date'))}
                      options={DATE_OPTIONS}
                      onChange={this.changeDateType}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames('field notes', styles.field)}>
                <div className="note">
                  <label htmlFor="note">
                    <p className={classNames('title', styles.title)}>Free Form Notes</p>
                  </label>
                  <div className={styles.selectFields}>
                    <input
                      maxLength="999"
                      name="note"
                      disabled={this.type('note') === 'empty' || this.type('note') === 'not_empty'}
                      className="input is-large"
                      type="text"
                      placeholder="Free Form Notes"
                      value={this.value('note')}
                      onChange={this.changeBaseInput}
                    />
                    <Select
                      name="note"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={TYPE_OPTIONS.find(el => el.value === this.type('note'))}
                      options={TYPE_OPTIONS}
                      onChange={this.changeType}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames('field notes', styles.field)}>
                <div className="notes">
                  <label htmlFor="notes">
                    <p className={classNames('title', styles.title)}>Notes</p>
                  </label>
                  <div className={styles.selectFields}>
                    <input
                      maxLength="999"
                      name="notes"
                      disabled={this.type('notes') === 'empty' || this.type('notes') === 'not_empty'}
                      className="input is-large"
                      type="text"
                      placeholder="Notes"
                      value={this.value('notes')}
                      onChange={this.changeBaseInput}
                    />
                    <Select
                      name="notes"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={TYPE_OPTIONS.find(el => el.value === this.type('notes'))}
                      options={TYPE_OPTIONS}
                      onChange={this.changeType}
                    />
                  </div>
                </div>
              </div>
              {!config().DISABLED_ACTIVITY_TRACKING && (
                <div className={styles.checkboxWrapper}>
                  <div className={classNames('field attempts', styles.field)}>
                    <label htmlFor="attempts">
                      <p className={classNames('title', styles.title)}>Attempts</p>
                    </label>
                    <input
                      id="attempts"
                      type="checkbox"
                      name="attempts"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeBoolean('attempts')}
                      checked={this.value('attempts')}
                    />
                    <label htmlFor="attempts" />
                  </div>
                  <div className={classNames('field contact', styles.field)}>
                    <label htmlFor="contact">
                      <p className={classNames('title', styles.title)}>Contact</p>
                    </label>
                    <input
                      id="contact"
                      type="checkbox"
                      name="contact"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeBoolean('contact')}
                      checked={this.value('contact')}
                    />
                    <label htmlFor="contact" />
                  </div>
                  <div className={classNames('field productinfo', styles.field)}>
                    <label htmlFor="product_info">
                      <p className={classNames('title', styles.title)}>Product Info</p>
                    </label>
                    <input
                      id="product_info"
                      type="checkbox"
                      name="product_info"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeBoolean('product_info')}
                      checked={this.value('product_info')}
                    />
                    <label htmlFor="product_info" />
                  </div>
                  <div className={classNames('field apptset', styles.field)}>
                    <label htmlFor="appt_set">
                      <p className={classNames('title', styles.title)}>Appt Set</p>
                    </label>
                    <input
                      id="appt_set"
                      type="checkbox"
                      name="appt_set"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeBoolean('appt_set')}
                      checked={this.value('appt_set')}
                    />
                    <label htmlFor="appt_set" />
                  </div>
                </div>
              )}
              {config().CONTACT_PERMISSIONS && (
                <div className={styles.checkboxWrapper}>
                  {!config().MYASBFINANCIAL && (
                    <div className={classNames('field callpermission', styles.field)}>
                      <label htmlFor="call_permission">
                        <p className={classNames('title', styles.title)}>Call Permission</p>
                      </label>
                      <input
                        id="call_permission"
                        type="checkbox"
                        name="call_permission"
                        className="is-checkradio is-medium"
                        onChange={() => this.changeBoolean('call_permission')}
                        checked={this.value('call_permission')}
                      />
                      <label htmlFor="call_permission" />
                    </div>
                  )}
                  <div className={classNames('field textpermission', styles.field)}>
                    <label htmlFor="text_permission">
                      <p className={classNames('title', styles.title)}>Text Permission</p>
                    </label>
                    <input
                      id="text_permission"
                      type="checkbox"
                      name="text_permission"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeBoolean('text_permission')}
                      checked={this.value('text_permission')}
                    />
                    <label htmlFor="text_permission" />
                  </div>
                  {!config().MYASBFINANCIAL && (
                    <div className={classNames('field emailpermission', styles.field)}>
                      <label htmlFor="email_permission">
                        <p className={classNames('title', styles.title)}>Email Permission</p>
                      </label>
                      <input
                        id="email_permission"
                        type="checkbox"
                        name="email_permission"
                        className="is-checkradio is-medium"
                        onChange={() => this.changeBoolean('email_permission')}
                        checked={this.value('email_permission')}
                      />
                      <label htmlFor="email_permission" />
                    </div>
                  )}
                </div>
              )}
              <div className={classNames('field filefolder', styles.field)}>
                <div className="files_present">
                  <label htmlFor="files_present">
                    <p className={classNames('title', styles.title)}>File Folder</p>
                  </label>
                  <Select
                    name="files_present"
                    className={classNames('custom-select')}
                    classNamePrefix="custom-select"
                    value={INCLUDE_EXCLUDE_OPTIONS.find(el => el.value === this.type('files_present'))}
                    options={INCLUDE_EXCLUDE_OPTIONS}
                    onChange={this.changeIncludeExcludeValue}
                  />
                </div>
              </div>

              <AdvancedSearchItem
                label="File Name"
                param="file_name"
                value={this.value}
                type={this.type}
                changeType={this.changeType}
                changeBaseInput={this.changeBaseInput}
                submitOnEnter={this.submitOnEnter}
                options={TYPE_OPTIONS}
              />
              <div className={classNames('field tobacco', styles.field, styles.customIncludes)}>
                <div className="tobacco">
                  <label htmlFor="tobacco">
                    <p className={classNames('title', styles.title)}>Tobacco Use</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="tobacco"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('tobacco', 'include')}
                        options={this.validOptions(TOBACCO, this.valueIncludeExclude('tobacco', 'exclude'))}
                        onChange={e => this.changeSelect(e, 'tobacco', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="tobacco"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to exclude..."
                        value={this.valueIncludeExclude('tobacco', 'exclude')}
                        options={this.validOptions(TOBACCO, this.valueIncludeExclude('tobacco', 'include'))}
                        onChange={e => this.changeSelect(e, 'tobacco', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {!config().CAREINADVANCE && !config().AEGLEADS && (
                <>
                  <div className={classNames('field contacttype', styles.field, styles.customIncludes)}>
                    <div className="data_capture_data_type">
                      <label htmlFor="data_capture_data_type">
                        <p className={classNames('title', styles.title)}>Contact Type</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="data_capture_data_type"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('data_capture_data_type', 'include')}
                            options={this.validOptions(
                              DATA_TYPE,
                              this.valueIncludeExclude('data_capture_data_type', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'data_capture_data_type', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="data_capture_data_type"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('data_capture_data_type', 'exclude')}
                            options={this.validOptions(
                              DATA_TYPE,
                              this.valueIncludeExclude('data_capture_data_type', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'data_capture_data_type', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field producttype', styles.field, styles.customIncludes)}>
                    <div className="data_capture_plan_type">
                      <label htmlFor="data_capture_plan_type">
                        <p className={classNames('title', styles.title)}>Product Type</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="data_capture_plan_type"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('data_capture_plan_type', 'include')}
                            options={this.validOptions(
                              PLAN_TYPE,
                              this.valueIncludeExclude('data_capture_plan_type', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'data_capture_plan_type', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="data_capture_plan_type"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('data_capture_plan_type', 'exclude')}
                            options={this.validOptions(
                              PLAN_TYPE,
                              this.valueIncludeExclude('data_capture_plan_type', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'data_capture_plan_type', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field carrier', styles.field, styles.customIncludes)}>
                    <div className="data_capture_carrier">
                      <label htmlFor="data_capture_carrier">
                        <p className={classNames('title', styles.title)}>Carrier</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p className={styles.description}>Include:</p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <input
                                id="data_capture_carrier_include_all"
                                type="checkbox"
                                value={'all'}
                                className="is-checkradio is-medium"
                                checked={this.state.includeAllCarriers}
                                onChange={e => this.selectAllCarriers(e, 'include')}
                              />
                              <label htmlFor="data_capture_carrier_include_all">
                                <p className={classNames('title', styles.title)}>All carriers</p>
                              </label>
                            </div>
                          </div>
                          <Select
                            name="data_capture_carrier"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('data_capture_carrier', 'include')}
                            options={this.validOptions(
                              this.normaliseOptions(this.state.carriers),
                              this.valueIncludeExclude('data_capture_carrier', 'exclude')
                            )}
                            onChange={e => this.changeSelectWithAll(e, 'data_capture_carrier', 'include')}
                            isOptionDisabled={this.isAnyAllCarriersSelected}
                            isDisabled={this.state.excludeAllCarriers}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p className={styles.description}>Exclude:</p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <input
                                id="data_capture_carrier_exclude_all"
                                type="checkbox"
                                value={'all'}
                                className="is-checkradio is-medium"
                                checked={this.state.excludeAllCarriers}
                                onChange={e => this.selectAllCarriers(e, 'exclude')}
                              />
                              <label htmlFor="data_capture_carrier_exclude_all">
                                <p className={classNames('title', styles.title)}>All carriers</p>
                              </label>
                            </div>
                          </div>
                          <Select
                            name="data_capture_carrier"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('data_capture_carrier', 'exclude')}
                            options={this.validOptions(
                              this.normaliseOptions(this.state.carriers),
                              this.valueIncludeExclude('data_capture_carrier', 'include')
                            )}
                            onChange={e => this.changeSelectWithAll(e, 'data_capture_carrier', 'exclude')}
                            isOptionDisabled={this.isAnyAllCarriersSelected}
                            isDisabled={this.state.includeAllCarriers}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {config(this.props.currentUser).DATA_CAPTURE_DEFAULT_FIELDS && (
                    <AdvancedSearchItem
                      label="Carrier Name"
                      param="data_capture_carrier_text"
                      classes="carriername"
                      value={this.value}
                      type={this.type}
                      changeType={this.changeType}
                      changeBaseInput={this.changeBaseInput}
                      submitOnEnter={this.submitOnEnter}
                      options={TYPE_OPTIONS}
                    />
                  )}
                  <div className={classNames('field premium', styles.field)}>
                    <div className="data_capture_premium">
                      <label htmlFor="data_capture_premium">
                        <p className={classNames('title', styles.title)}>{config().BROKERAGEASB && 'Annual '}Premium</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          disabled={
                            this.type('data_capture_premium') === 'empty' ||
                            this.type('data_capture_premium') === 'not_empty'
                          }
                          value={this.value('data_capture_premium')}
                          placeholder="$0.00"
                          className="input is-large"
                          id="data_capture_premium"
                          name="data_capture_premium"
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="data_capture_premium"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('data_capture_premium'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {this.props.currentUser.aris && (
                <div className={classNames('field replacement', styles.field, styles.customIncludes)}>
                  <div className="replacement">
                    <label htmlFor="replacement">
                      <p className={classNames('title', styles.title)}>Replacement</p>
                    </label>
                    <div className={styles.includesWrapper}>
                      <div className={styles.includeItem}>
                        <p className={styles.description}>Include:</p>
                        <Select
                          name="replacement"
                          isMulti
                          className="custom-select with-include"
                          classNamePrefix="custom-select"
                          placeholder="Type to include..."
                          value={this.valueIncludeExclude('replacement', 'include')}
                          options={this.validOptions(REPLACEMENT, this.valueIncludeExclude('replacement', 'exclude'))}
                          onChange={e => this.changeSelect(e, 'replacement', 'include')}
                        />
                      </div>
                      <div className={styles.includeItem}>
                        <p className={styles.description}>Exclude:</p>
                        <Select
                          name="replacement"
                          isMulti
                          className="custom-select with-include"
                          classNamePrefix="custom-select"
                          placeholder="Type to exclude..."
                          value={this.valueIncludeExclude('replacement', 'exclude')}
                          options={this.validOptions(REPLACEMENT, this.valueIncludeExclude('replacement', 'include'))}
                          onChange={e => this.changeSelect(e, 'replacement', 'exclude')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={classNames('field tags', styles.field, styles.block, styles.customIncludes)}>
                <div className="control">
                  <label htmlFor="tags">
                    <p className={classNames('title', styles.title)}>Tags</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="tags"
                        isMulti
                        className="custom-select with-include "
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('tags', 'include')}
                        options={this.validOptions(
                          this.normaliseTagOptions(this.tagsOptions(this.props.allTags)),
                          this.valueIncludeExclude('tags', 'exclude')
                        )}
                        onChange={e => this.changeTags(e, 'include')}
                        onBlur={() => this.setState({ tag: '' })}
                        onInputChange={(tag, action) => {
                          if (action.action === 'input-change') this.setState({ tag });
                        }}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="tags"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to exclude..."
                        value={this.valueIncludeExclude('tags', 'exclude')}
                        options={this.validOptions(
                          this.normaliseTagOptions(this.tagsOptions(this.props.allTags)),
                          this.valueIncludeExclude('tags', 'include')
                        )}
                        onChange={e => this.changeTags(e, 'exclude')}
                        onBlur={() => this.setState({ tag: '' })}
                        onInputChange={(tag, action) => {
                          if (action.action === 'input-change') this.setState({ tag });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {!config().BROKERAGEASB && (
                <>
                  <div className={classNames('field writingagent1name', styles.field, styles.customIncludes)}>
                    <div className="writing_agent_1_name">
                      <label htmlFor="writing_agent_1_name">
                        <p className={classNames('title', styles.title)}>Writing Agent 1 Name</p>
                      </label>
                      <div className={styles.selectFields}>
                        <AsyncSelect
                          classNamePrefix="custom-select"
                          className={classNames('custom-select', styles.customSelectOwner)}
                          cacheOptions
                          isClearable
                          name="writing_agent_1_name"
                          placeholder="Writing Agent 1 Name"
                          value={this.state.writing_agent_1_name || []}
                          onChange={e => this.changeUser('writing_agent_1_name', e)}
                          defaultOptions
                          isMulti
                          loadOptions={this.loadOwners}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field writingagent1number', styles.field)}>
                    <div className="writing_agent_1_number">
                      <label htmlFor="writing_agent_1_number">
                        <p className={classNames('title', styles.title)}>Writing Agent 1 Number</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          autoComplete="new-password"
                          maxLength="999"
                          id="writing_agent_1_number"
                          name="writing_agent_1_number"
                          placeholder="Writing Agent 1 Number"
                          type="text"
                          className="input is-large"
                          onChange={e => this.changeBaseIncludeExclude(e, this.type('writing_agent_1_number'))}
                          value={this.value('writing_agent_1_number')}
                        />
                        <Select
                          name="writing_agent_1_number"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={SELECT_TYPES.find(el => el.value === this.type('writing_agent_1_number'))}
                          options={SELECT_TYPES}
                          onChange={(changedObj, e) => {
                            this.changeType(changedObj, e);
                          }}
                        />
                        <button
                          type="button"
                          onClick={() => this.addMultipleValue('writing_agent_1_number')}
                          className={classNames('button is-large is-secondary')}
                        >
                          + Add
                        </button>
                        <button
                          type="button"
                          onClick={() => this.removeMultipleValues('writing_agent_1_number')}
                          className={classNames('button is-large is-secondary')}
                        >
                          Remove All
                        </button>
                      </div>
                      {this.state.writing_agent_1_number.map(type => {
                        return (
                          <div key={type.type}>
                            <p>
                              {SELECT_TYPES.find(option => option.value === type.type).label}
                              {' :'}
                            </p>
                            <div className={styles.labelWrapper}>
                              {type?.value?.map(value => (
                                <p
                                  key={type.type + value}
                                  onClick={e => e.stopPropagation()}
                                  style={{ backgroundColor: 'grey' }}
                                  className={styles.labeled}
                                >
                                  {value}
                                  <i
                                    onClick={() => this.removeMultipleValue('writing_agent_1_number', type.type, value)}
                                    className="fas fa-times"
                                  />
                                </p>
                              ))}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={classNames('field writingagent2name', styles.field, styles.customIncludes)}>
                    <div className="writing_agent_2_name">
                      <label htmlFor="writing_agent_2_name">
                        <p className={classNames('title', styles.title)}>Writing Agent 2 Name</p>
                      </label>
                      <div className={styles.selectFields}>
                        <AsyncSelect
                          classNamePrefix="custom-select"
                          className={classNames('custom-select', styles.customSelectOwner)}
                          cacheOptions
                          isClearable
                          name="writing_agent_2_name"
                          placeholder="Writing Agent 2 Name"
                          value={this.state.writing_agent_2_name || []}
                          onChange={e => this.changeUser('writing_agent_2_name', e)}
                          defaultOptions
                          isMulti
                          loadOptions={this.loadOwners}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field writingagent2number', styles.field)}>
                    <div className="writing_agent_2_number">
                      <label htmlFor="writing_agent_2_number">
                        <p className={classNames('title', styles.title)}>Writing Agent 2 Number</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          autoComplete="new-password"
                          maxLength="999"
                          id="writing_agent_2_number"
                          name="writing_agent_2_number"
                          placeholder="Writing Agent 2 Number"
                          type="text"
                          className="input is-large"
                          onChange={e => this.changeBaseIncludeExclude(e, this.type('writing_agent_2_number'))}
                          value={this.value('writing_agent_2_number')}
                        />
                        <Select
                          name="writing_agent_2_number"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={SELECT_TYPES.find(el => el.value === this.type('writing_agent_2_number'))}
                          options={SELECT_TYPES}
                          onChange={(changedObj, e) => {
                            this.changeType(changedObj, e);
                          }}
                        />
                        <button
                          type="button"
                          onClick={() => this.addMultipleValue('writing_agent_2_number')}
                          className={classNames('button is-large is-secondary')}
                        >
                          + Add
                        </button>
                        <button
                          type="button"
                          onClick={() => this.removeMultipleValues('writing_agent_2_number')}
                          className={classNames('button is-large is-secondary')}
                        >
                          Remove All
                        </button>
                      </div>
                      {this.state.writing_agent_2_number.map(type => {
                        return (
                          <div key={type.type}>
                            <p>
                              {SELECT_TYPES.find(option => option.value === type.type).label}
                              {' :'}
                            </p>
                            <div className={styles.labelWrapper}>
                              {type?.value?.map(value => (
                                <p
                                  key={type.type + value}
                                  onClick={e => e.stopPropagation()}
                                  style={{ backgroundColor: 'grey' }}
                                  className={styles.labeled}
                                >
                                  {value}
                                  <i
                                    onClick={() => this.removeMultipleValue('writing_agent_2_number', type.type, value)}
                                    className="fas fa-times"
                                  />
                                </p>
                              ))}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={classNames('field writingagent3name', styles.field, styles.customIncludes)}>
                    <div className="writing_agent_3_name">
                      <label htmlFor="writing_agent_3_name">
                        <p className={classNames('title', styles.title)}>Writing Agent 3 Name</p>
                      </label>
                      <div className={styles.selectFields}>
                        <AsyncSelect
                          classNamePrefix="custom-select"
                          className={classNames('custom-select', styles.customSelectOwner)}
                          cacheOptions
                          isClearable
                          name="writing_agent_3_name"
                          placeholder="Writing Agent 3 Name"
                          value={this.state.writing_agent_3_name || []}
                          onChange={e => this.changeUser('writing_agent_3_name', e)}
                          defaultOptions
                          isMulti
                          loadOptions={this.loadOwners}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field writingagent3number', styles.field)}>
                    <div className="writing_agent_3_number">
                      <label htmlFor="writing_agent_3_number">
                        <p className={classNames('title', styles.title)}>Writing Agent 3 Number</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          autoComplete="new-password"
                          maxLength="999"
                          id="writing_agent_3_number"
                          name="writing_agent_3_number"
                          placeholder="Writing Agent 3 Number"
                          type="text"
                          className="input is-large"
                          onChange={e => this.changeBaseIncludeExclude(e, this.type('writing_agent_3_number'))}
                          value={this.value('writing_agent_3_number')}
                        />
                        <Select
                          name="writing_agent_3_number"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={SELECT_TYPES.find(el => el.value === this.type('writing_agent_3_number'))}
                          options={SELECT_TYPES}
                          onChange={(changedObj, e) => {
                            this.changeType(changedObj, e);
                          }}
                        />
                        <button
                          type="button"
                          onClick={() => this.addMultipleValue('writing_agent_3_number')}
                          className={classNames('button is-large is-secondary')}
                        >
                          + Add
                        </button>
                        <button
                          type="button"
                          onClick={() => this.removeMultipleValues('writing_agent_3_number')}
                          className={classNames('button is-large is-secondary')}
                        >
                          Remove All
                        </button>
                      </div>
                      {this.state.writing_agent_3_number.map(type => {
                        return (
                          <div key={type.type}>
                            <p>
                              {SELECT_TYPES.find(option => option.value === type.type).label}
                              {' :'}
                            </p>
                            <div className={styles.labelWrapper}>
                              {type?.value?.map(value => (
                                <p
                                  key={type.type + value}
                                  onClick={e => e.stopPropagation()}
                                  style={{ backgroundColor: 'grey' }}
                                  className={styles.labeled}
                                >
                                  {value}
                                  <i
                                    onClick={() => this.removeMultipleValue('writing_agent_3_number', type.type, value)}
                                    className="fas fa-times"
                                  />
                                </p>
                              ))}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
              <div className={classNames('field policynumber', styles.block, styles.field)}>
                <div className="policy_number">
                  <label htmlFor="policy_number">
                    <p className={classNames('title', styles.title)}>Policy Number</p>
                  </label>
                  <div className={styles.selectFields}>
                    <input
                      maxLength="999"
                      name="policy_number"
                      disabled={this.type('policy_number') === 'empty' || this.type('policy_number') === 'not_empty'}
                      className="input is-large"
                      type="text"
                      placeholder="Policy Number"
                      value={this.value('policy_number')}
                      onChange={this.changeBaseInput}
                    />
                    <Select
                      name="policy_number"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={TYPE_OPTIONS.find(el => el.value === this.type('policy_number'))}
                      options={TYPE_OPTIONS}
                      onChange={this.changeType}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames('field policyholderstatus', styles.field, styles.customIncludes)}>
                <div className="policy_status">
                  <label htmlFor="policy_status">
                    <p className={classNames('title', styles.title)}>
                      {' '}
                      {config().MYASBFINANCIAL ? 'Official ASB Client Designation' : 'Policy Holder Status'}
                    </p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="policy_status"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('policy_status', 'include')}
                        options={this.validOptions(
                          CONTACT_POLICY_STATUSES(this.props.currentUser),
                          this.valueIncludeExclude('policy_status', 'exclude')
                        )}
                        onChange={e => this.changeSelect(e, 'policy_status', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="policy_status"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to exclude..."
                        value={this.valueIncludeExclude('policy_status', 'exclude')}
                        options={this.validOptions(
                          CONTACT_POLICY_STATUSES(this.props.currentUser),
                          this.valueIncludeExclude('policy_status', 'include')
                        )}
                        onChange={e => this.changeSelect(e, 'policy_status', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {config().PLAN && (
                <>
                  <div className={classNames('field planid', styles.field)}>
                    <div className="plan_id">
                      <label htmlFor="plan_id">
                        <p className={classNames('title', styles.title)}>Plan ID</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          disabled={this.type('plan_id') === 'empty' || this.type('plan_id') === 'not_empty'}
                          name="plan_id"
                          className="input is-large"
                          type="text"
                          placeholder="Plan ID"
                          value={this.value('plan_id')}
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="plan_id"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('plan_id'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field plan', styles.field)}>
                    <div className="plan">
                      <label htmlFor="plan">
                        <p className={classNames('title', styles.title)}>Plan</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          disabled={this.type('plan') === 'empty' || this.type('plan') === 'not_empty'}
                          name="plan"
                          className="input is-large"
                          type="text"
                          placeholder="Plan"
                          value={this.value('plan')}
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="plan"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('plan'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className={classNames('field policystatus', styles.field, styles.customIncludes)}>
                <div className="policies_status">
                  <label htmlFor="policies_status">
                    <p className={classNames('title', styles.title)}>Policy Status</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="policies_status"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('policies_status', 'include')}
                        options={this.validOptions(
                          POLICY_STATUSES(this.props.currentUser),
                          this.valueIncludeExclude('policies_status', 'exclude')
                        )}
                        onChange={e => this.changeSelect(e, 'policies_status', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="policies_status"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to exclude..."
                        value={this.valueIncludeExclude('policies_status', 'exclude')}
                        options={this.validOptions(
                          POLICY_STATUSES(this.props.currentUser),
                          this.valueIncludeExclude('policies_status', 'include')
                        )}
                        onChange={e => this.changeSelect(e, 'policies_status', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {this.isRmCIA && (
                <div className={classNames('field role', styles.field, styles.customIncludes)}>
                  <div className="role">
                    <label htmlFor="role">
                      <p className={classNames('title', styles.title)}>Role</p>
                    </label>
                    <div className={styles.includesWrapper}>
                      <div className={styles.includeItem}>
                        <p className={styles.description}>Include:</p>
                        <Select
                          name="role"
                          isMulti
                          className="custom-select with-include"
                          classNamePrefix="custom-select"
                          placeholder="Type to include..."
                          value={this.valueIncludeExclude('role', 'include')}
                          options={this.validOptions(RM_CONTACT_ROLES, this.valueIncludeExclude('role', 'exclude'))}
                          onChange={e => this.changeSelect(e, 'role', 'include')}
                        />
                      </div>
                      <div className={styles.includeItem}>
                        <p className={styles.description}>Exclude:</p>
                        <Select
                          name="role"
                          isMulti
                          className="custom-select with-include"
                          classNamePrefix="custom-select"
                          placeholder="Type to exclude..."
                          value={this.valueIncludeExclude('role', 'exclude')}
                          options={this.validOptions(RM_CONTACT_ROLES, this.valueIncludeExclude('role', 'include'))}
                          onChange={e => this.changeSelect(e, 'role', 'exclude')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={classNames('field policytype', styles.field, styles.customIncludes)}>
                <div className="policy_type">
                  <label htmlFor="policy_type">
                    <p className={classNames('title', styles.title)}>Policy Type</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="policy_type"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('policy_type', 'include')}
                        options={this.validOptions(
                          POLICY_TYPES(this.props.currentUser),
                          this.valueIncludeExclude('policy_type', 'exclude')
                        )}
                        onChange={e => this.changeSelect(e, 'policy_type', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="policy_type"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to exclude..."
                        value={this.valueIncludeExclude('policy_type', 'exclude')}
                        options={this.validOptions(
                          POLICY_TYPES(this.props.currentUser),
                          this.valueIncludeExclude('policy_type', 'include')
                        )}
                        onChange={e => this.changeSelect(e, 'policy_type', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames('field policyeffectiveday', styles.field)}>
                <div className={classNames('policy_effective_day')}>
                  <label htmlFor="policy_effective_day">
                    <p className={classNames('title', styles.title)}>Policy Effective Day</p>
                  </label>
                  <div className={classNames(styles.dateFields)}>
                    {this.type('policy_effective_day') !== 'range' && (
                      <PatternFormat
                        className={classNames('input is-large', styles.policyEffectiveDay, styles.dateStart)}
                        id="policy_effective_day"
                        format="##/##"
                        placeholder="MM/DD"
                        isAllowed={validateDay}
                        mask={'_'}
                        name="policy_effective_day"
                        value={this.value('policy_effective_day')}
                        onChange={e => this.changeDate(e, 'policy_effective_day')}
                        disabled={
                          this.type('policy_effective_day') === 'all' || this.type('policy_effective_day') === 'none'
                        }
                      />
                    )}
                    {this.type('policy_effective_day') === 'range' && (
                      <>
                        <PatternFormat
                          className={classNames('input is-large', styles.policyEffectiveDay, styles.dateStart)}
                          format="##/##"
                          placeholder="MM/DD"
                          mask={'_'}
                          isAllowed={validateDay}
                          name="policy_effective_day"
                          value={this.valueRangeStart('policy_effective_day')}
                          onChange={e => this.changeDateRangeStart(e, 'policy_effective_day')}
                        />
                        <PatternFormat
                          className={classNames('input is-large', styles.policyEffectiveDay, styles.dateEnd)}
                          format="##/##"
                          placeholder="MM/DD"
                          mask={'_'}
                          isAllowed={validateDay}
                          name="policy_effective_day"
                          value={this.valueRangeEnd('policy_effective_day')}
                          onChange={e => this.changeDateRangeEnd(e, 'policy_effective_day')}
                          disabled={!this.valueRangeStart('policy_effective_day')}
                        />
                      </>
                    )}
                    <Select
                      name="policy_effective_day"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={DATE_OPTIONS.find(el => el.value === this.type('policy_effective_day'))}
                      options={DATE_OPTIONS.slice(0, -3)}
                      onChange={this.changeDateType}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames('field policyeffectivedate', styles.field)}>
                <div className="policy_effective_date">
                  <label htmlFor="policy_effective_date">
                    <p className={classNames('title', styles.title)}>Policy Effective Date</p>
                  </label>
                  <div className={styles.dateFields}>
                    {this.type('policy_effective_date') !== 'range' && (
                      <DatePicker
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        closeOnScroll={true}
                        id="policy_effective_date"
                        maxDate={new Date()}
                        selected={parseDateUTC(this.value('policy_effective_date'))}
                        onChange={e => this.changeDate(e, 'policy_effective_date')}
                        name="policy_effective_date"
                        placeholderText="mm/dd/yyyy"
                        dateFormat="MM/dd/yyyy"
                        className={classNames('input is-large', styles.dateStart)}
                        disabled={
                          this.type('policy_effective_date') === 'all' || this.type('policy_effective_date') === 'none'
                        }
                      />
                    )}

                    {this.type('policy_effective_date') === 'range' && (
                      <>
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          selected={parseDateUTC(this.valueRangeStart('policy_effective_date'))}
                          onChange={e => this.changeDateRangeStart(e, 'policy_effective_date')}
                          name="policy_effective_date"
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateStart)}
                        />
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          minDate={parseDateUTC(this.valueRangeStart('policy_effective_date'))}
                          selected={parseDateUTC(this.valueRangeEnd('policy_effective_date'))}
                          onChange={e => this.changeDateRangeEnd(e, 'policy_effective_date')}
                          name="policy_effective_date"
                          disabled={!this.valueRangeStart('policy_effective_date')}
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateEnd)}
                        />
                      </>
                    )}
                    <Select
                      name="policy_effective_date"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={DATE_OPTIONS.find(el => el.value === this.type('policy_effective_date'))}
                      options={DATE_OPTIONS}
                      onChange={this.changeDateType}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames('field policycarrier', styles.field, styles.customIncludes)}>
                <div className="policy_carrier">
                  <label htmlFor="policy_carrier">
                    <p className={classNames('title', styles.title)}>Policy Carrier</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <input
                        maxLength="999"
                        name="policy_carrier"
                        placeholder="Type carriers users, to include..."
                        className="input is-large"
                        type="text"
                        value={this.valueIncludeExclude('policy_carrier', 'include')}
                        onChange={e => this.changeBaseIncludeExclude(e, 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <input
                        maxLength="999"
                        name="policy_carrier"
                        placeholder="Type carriers users, to exclude..."
                        className="input is-large"
                        type="text"
                        value={this.valueIncludeExclude('policy_carrier', 'exclude')}
                        onChange={e => this.changeBaseIncludeExclude(e, 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames('field policynote', styles.field)}>
                <div className="policy_note">
                  <label htmlFor="policy_note">
                    <p className={classNames('title', styles.title)}>Policy Note</p>
                  </label>
                  <div className={styles.selectFields}>
                    <input
                      maxLength="999"
                      name="policy_note"
                      disabled={this.type('policy_note') === 'empty' || this.type('policy_note') === 'not_empty'}
                      className="input is-large"
                      type="text"
                      placeholder="Policy Note"
                      value={this.value('policy_note')}
                      onChange={this.changeBaseInput}
                    />
                    <Select
                      name="policy_note"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={TYPE_OPTIONS.find(el => el.value === this.type('policy_note'))}
                      options={TYPE_OPTIONS}
                      onChange={this.changeType}
                    />
                  </div>
                </div>
              </div>
              {config().RX_DETAILS && (
                <>
                  <div className={classNames('field rxdetailsproducttype', styles.field, styles.customIncludes)}>
                    <div className="rx_details_plan_type">
                      <label htmlFor="rx_details_plan_type">
                        <p className={classNames('title', styles.title)}>RX Details: Product Type</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="rx_details_plan_type"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('rx_details_plan_type', 'include')}
                            options={this.validOptions(
                              RX_DETAILS_PLAN_TYPES,
                              this.valueIncludeExclude('rx_details_plan_type', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'rx_details_plan_type', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="rx_details_plan_type"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('rx_details_plan_type', 'exclude')}
                            options={this.validOptions(
                              RX_DETAILS_PLAN_TYPES,
                              this.valueIncludeExclude('rx_details_plan_type', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'rx_details_plan_type', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field rxdetailsrecordtype', styles.field, styles.customIncludes)}>
                    <div className="rx_details_record_type">
                      <label htmlFor="rx_details_record_type">
                        <p className={classNames('title', styles.title)}>RX Details: Record Type</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="rx_details_record_type"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('rx_details_record_type', 'include')}
                            options={this.validOptions(
                              RX_DETAILS_RECORD_TYPES,
                              this.valueIncludeExclude('rx_details_record_type', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'rx_details_record_type', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="rx_details_record_type"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('rx_details_record_type', 'exclude')}
                            options={this.validOptions(
                              RX_DETAILS_RECORD_TYPES,
                              this.valueIncludeExclude('rx_details_record_type', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'rx_details_record_type', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field rxdetailslettersent', styles.field, styles.customIncludes)}>
                    <div className="rx_details_letter_sent">
                      <label htmlFor="rx_details_letter_sent">
                        <p className={classNames('title', styles.title)}>RX Details: Letter Sent</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="rx_details_letter_sent"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('rx_details_letter_sent', 'include')}
                            options={this.validOptions(
                              RX_DETAILS_LETTER_SENT,
                              this.valueIncludeExclude('rx_details_letter_sent', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'rx_details_letter_sent', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="rx_details_letter_sent"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('rx_details_letter_sent', 'exclude')}
                            options={this.validOptions(
                              RX_DETAILS_LETTER_SENT,
                              this.valueIncludeExclude('rx_details_letter_sent', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'rx_details_letter_sent', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className={classNames('field importtype', styles.field, styles.customIncludes)}>
                <div className="import_type">
                  <label htmlFor="import_type">
                    <p className={classNames('title', styles.title)}>Import Type</p>
                  </label>
                  <div className={styles.includesWrapper}>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Include:</p>
                      <Select
                        name="import_type"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to include..."
                        value={this.valueIncludeExclude('import_type', 'include')}
                        options={this.validOptions(
                          this.props.currentUser.admin ? TYPES_FOR_ADMIN : TYPES,
                          this.valueIncludeExclude('import_type', 'exclude')
                        )}
                        onChange={e => this.changeSelect(e, 'import_type', 'include')}
                      />
                    </div>
                    <div className={styles.includeItem}>
                      <p className={styles.description}>Exclude:</p>
                      <Select
                        name="import_type"
                        isMulti
                        className="custom-select with-include"
                        classNamePrefix="custom-select"
                        placeholder="Type to exclude..."
                        value={this.valueIncludeExclude('import_type', 'exclude')}
                        options={this.validOptions(
                          this.props.currentUser.admin ? TYPES_FOR_ADMIN : TYPES,
                          this.valueIncludeExclude('import_type', 'include')
                        )}
                        onChange={e => this.changeSelect(e, 'import_type', 'exclude')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {this.props.currentUser.c_1 && (
                <div className={classNames('field calendarevent', styles.field)}>
                  <div className="calendar_event">
                    <label htmlFor="calendar_event">
                      <p className={classNames('title', styles.title)}>Calendar Event</p>
                    </label>
                    <div className={styles.dateFields}>
                      {this.type('calendar_event') !== 'range' && (
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          id="calendar_event"
                          selected={parseDateUTC(this.value('calendar_event'))}
                          onChange={e => this.changeDate(e, 'calendar_event')}
                          name="calendar_event"
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateStart)}
                          disabled={this.type('calendar_event') === 'all' || this.type('calendar_event') === 'none'}
                        />
                      )}

                      {this.type('calendar_event') === 'range' && (
                        <>
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            closeOnScroll={true}
                            selected={parseDateUTC(this.valueRangeStart('calendar_event'))}
                            onChange={e => this.changeDateRangeStart(e, 'calendar_event')}
                            name="notes_added_date"
                            placeholderText="mm/dd/yyyy"
                            dateFormat="MM/dd/yyyy"
                            className={classNames('input is-large', styles.dateStart)}
                          />
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            closeOnScroll={true}
                            minDate={parseDateUTC(this.valueRangeStart('calendar_event'))}
                            selected={parseDateUTC(this.valueRangeEnd('calendar_event'))}
                            onChange={e => this.changeDateRangeEnd(e, 'calendar_event')}
                            name="notes_added_date"
                            disabled={!this.valueRangeStart('calendar_event')}
                            placeholderText="mm/dd/yyyy"
                            dateFormat="MM/dd/yyyy"
                            className={classNames('input is-large', styles.dateEnd)}
                          />
                        </>
                      )}
                      <Select
                        name="calendar_event"
                        className={classNames('custom-select', styles.customSelect)}
                        classNamePrefix="custom-select"
                        value={DATE_OPTIONS.find(el => el.value === this.type('calendar_event'))}
                        options={DATE_OPTIONS}
                        onChange={this.changeDateType}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={classNames('field newslettersent', styles.field)}>
                <div className="newsletter_sent_custom">
                  <label htmlFor="newsletter_sent_custom">
                    <p className={classNames('title', styles.title)}>Newsletter Sent Custom</p>
                  </label>
                  <div className={styles.dateFields}>
                    {this.type('newsletter_sent_custom') !== 'range' && (
                      <DatePicker
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        closeOnScroll={true}
                        id="newsletter_sent_custom"
                        maxDate={new Date()}
                        selected={parseDateUTC(this.value('newsletter_sent_custom'))}
                        onChange={e => this.changeDate(e, 'newsletter_sent_custom')}
                        name="newsletter_sent_custom"
                        placeholderText="mm/dd/yyyy"
                        dateFormat="MM/dd/yyyy"
                        className={classNames('input is-large', styles.dateStart)}
                        disabled={
                          this.type('newsletter_sent_custom') === 'all' ||
                          this.type('newsletter_sent_custom') === 'none'
                        }
                      />
                    )}
                    {this.type('newsletter_sent_custom') === 'range' && (
                      <>
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          selected={parseDateUTC(this.valueRangeStart('newsletter_sent_custom'))}
                          onChange={e => this.changeDateRangeStart(e, 'newsletter_sent_custom')}
                          name="newsletter_sent_custom"
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateStart)}
                        />
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          minDate={parseDateUTC(this.valueRangeStart('newsletter_sent_custom'))}
                          selected={parseDateUTC(this.valueRangeEnd('newsletter_sent_custom'))}
                          onChange={e => this.changeDateRangeEnd(e, 'newsletter_sent_custom')}
                          name="newsletter_sent_custom"
                          disabled={!this.valueRangeStart('newsletter_sent_custom')}
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateEnd)}
                        />
                      </>
                    )}
                    <Select
                      name="newsletter_sent_custom"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={DATE_OPTIONS.find(el => el.value === this.type('newsletter_sent_custom'))}
                      options={DATE_OPTIONS}
                      onChange={this.changeDateType}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames('field newsletteropened', styles.field)}>
                <div className="newsletter_opened_custom">
                  <label htmlFor="newsletter_opened_custom">
                    <p className={classNames('title', styles.title)}>Newsletter Opened Custom</p>
                  </label>
                  <div className={styles.dateFields}>
                    {this.type('newsletter_opened_custom') !== 'range' && (
                      <DatePicker
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        closeOnScroll={true}
                        id="newsletter_opened_custom"
                        maxDate={new Date()}
                        selected={parseDateUTC(this.value('newsletter_opened_custom'))}
                        onChange={e => this.changeDate(e, 'newsletter_opened_custom')}
                        name="newsletter_opened_custom"
                        placeholderText="mm/dd/yyyy"
                        dateFormat="MM/dd/yyyy"
                        className={classNames('input is-large', styles.dateStart)}
                        disabled={
                          this.type('newsletter_opened_custom') === 'all' ||
                          this.type('newsletter_opened_custom') === 'none'
                        }
                      />
                    )}
                    {this.type('newsletter_opened_custom') === 'range' && (
                      <>
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          selected={parseDateUTC(this.valueRangeStart('newsletter_opened_custom'))}
                          onChange={e => this.changeDateRangeStart(e, 'newsletter_opened_custom')}
                          name="newsletter_opened_custom"
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateStart)}
                        />
                        <DatePicker
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          closeOnScroll={true}
                          maxDate={new Date()}
                          minDate={parseDateUTC(this.valueRangeStart('newsletter_opened_custom'))}
                          selected={parseDateUTC(this.valueRangeEnd('newsletter_opened_custom'))}
                          onChange={e => this.changeDateRangeEnd(e, 'newsletter_opened_custom')}
                          name="newsletter_opened_custom"
                          disabled={!this.valueRangeStart('newsletter_opened_custom')}
                          placeholderText="mm/dd/yyyy"
                          dateFormat="MM/dd/yyyy"
                          className={classNames('input is-large', styles.dateEnd)}
                        />
                      </>
                    )}
                    <Select
                      name="newsletter_opened_custom"
                      className={classNames('custom-select', styles.customSelect)}
                      classNamePrefix="custom-select"
                      value={DATE_OPTIONS.find(el => el.value === this.type('newsletter_opened_custom'))}
                      options={DATE_OPTIONS}
                      onChange={this.changeDateType}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.checkboxWrapper}>
                <div className={classNames('field policyanniversary', styles.field)}>
                  <label htmlFor="policy_anniversary">
                    <p className={classNames('title', styles.title)}>Policy Anniversary</p>
                  </label>
                  <input
                    id="policy_anniversary"
                    type="checkbox"
                    name="policy_anniversary"
                    className="is-checkradio is-medium"
                    onChange={() => this.changeBoolean('policy_anniversary')}
                    checked={this.value('policy_anniversary')}
                  />
                  <label htmlFor="policy_anniversary" />
                </div>
                {this.props.currentUser.c_1 && (
                  <div className={classNames('field pastdueevents', styles.field)}>
                    <label htmlFor="past_due_events">
                      <p className={classNames('title', styles.title)}>Past Due Events</p>
                    </label>
                    <input
                      id="past_due_events"
                      type="checkbox"
                      name="past_due_events"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeBoolean('past_due_events')}
                      checked={this.value('past_due_events')}
                    />
                    <label htmlFor="past_due_events" />
                  </div>
                )}
                {!config().BROKERAGEASB && (
                  <>
                    <div className={classNames('field newslettersent', styles.field)}>
                      <label htmlFor="newsletter_sent">
                        <p className={classNames('title', styles.title)}>Newsletter Sent</p>
                      </label>
                      <input
                        id="newsletter_sent"
                        type="checkbox"
                        name="newsletter_sent"
                        className="is-checkradio is-medium"
                        onChange={() => this.changeBoolean('newsletter_sent')}
                        checked={this.value('newsletter_sent')}
                      />
                      <label htmlFor="newsletter_sent" />
                    </div>
                    <div className={classNames('field newsletteropened', styles.field)}>
                      <label htmlFor="newsletter_opened">
                        <p className={classNames('title', styles.title)}>Newsletter Opened</p>
                      </label>
                      <input
                        id="newsletter_opened"
                        type="checkbox"
                        name="newsletter_opened"
                        className="is-checkradio is-medium"
                        onChange={() => this.changeBoolean('newsletter_opened')}
                        checked={this.value('newsletter_opened')}
                      />
                      <label htmlFor="newsletter_opened" />
                    </div>
                  </>
                )}
              </div>
              {this.props.currentUser.aris && (
                <div className={styles.checkboxWrapper}>
                  <div className={classNames('field newcontactsforolderpolicies', styles.field)}>
                    <label htmlFor="older_policies">
                      <p className={classNames('title', styles.title)}>New Contacts for Older Policies</p>
                    </label>
                    <input
                      id="older_policies"
                      type="checkbox"
                      name="older_policies"
                      className="is-checkradio is-medium"
                      onChange={() => this.changeBoolean('older_policies')}
                      checked={this.value('older_policies')}
                    />
                    <label htmlFor="older_policies" />
                  </div>
                </div>
              )}
              {config().CAREINADVANCE && (
                <>
                  <div className={classNames('field faceamount', styles.field)}>
                    <div className="face_amount">
                      <label htmlFor="face_amount">
                        <p className={classNames('title', styles.title)}>Face Amount</p>
                      </label>
                      <div className={styles.selectFields}>
                        <CurrencyInput
                          disabled={this.type('face_amount') === 'empty' || this.type('face_amount') === 'not_empty'}
                          value={this.value('face_amount')}
                          placeholder="$0.00"
                          className="input is-large"
                          id="face_amount"
                          name="face_amount"
                          onChange={value => this.changeCurrency('face_amount', value)}
                        />
                        <Select
                          name="face_amount"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('face_amount'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field funerealservices', styles.field, styles.customIncludes)}>
                    <div className="funereal_services">
                      <label htmlFor="funereal_services">
                        <p className={classNames('title', styles.title)}>Funeral Service</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="funereal_services"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('funereal_services', 'include')}
                            options={this.validOptions(
                              FUNERAL_SERVICE,
                              this.valueIncludeExclude('funereal_services', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'funereal_services', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="funereal_services"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('funereal_services', 'exclude')}
                            options={this.validOptions(
                              FUNERAL_SERVICE,
                              this.valueIncludeExclude('funereal_services', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'funereal_services', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field remainsdispositions', styles.field, styles.customIncludes)}>
                    <div className="remains_dispositions">
                      <label htmlFor="remains_dispositions">
                        <p className={classNames('title', styles.title)}>Disposition of Remains</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="remains_dispositions"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('remains_dispositions', 'include')}
                            options={this.validOptions(
                              DISPOSITION_OF_REMAINS,
                              this.valueIncludeExclude('remains_dispositions', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'remains_dispositions', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="remains_dispositions"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('remains_dispositions', 'exclude')}
                            options={this.validOptions(
                              DISPOSITION_OF_REMAINS,
                              this.valueIncludeExclude('remains_dispositions', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'remains_dispositions', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field languages', styles.field, styles.customIncludes)}>
                    <div className="languages">
                      <label htmlFor="languages">
                        <p className={classNames('title', styles.title)}>Language</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="languages"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('languages', 'include')}
                            options={this.validOptions(LANGUAGE, this.valueIncludeExclude('languages', 'exclude'))}
                            onChange={e => this.changeSelect(e, 'languages', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="languages"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('languages', 'exclude')}
                            options={this.validOptions(LANGUAGE, this.valueIncludeExclude('languages', 'include'))}
                            onChange={e => this.changeSelect(e, 'languages', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {config().ASBAGENTMN && (
                <>
                  <div className={classNames('field givento', styles.field, styles.block, styles.customIncludes)}>
                    <div className="control">
                      <label htmlFor="given_to">
                        <p className={classNames('title', styles.title)}>P/C Given to</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="given_to"
                            isMulti
                            className="custom-select with-include "
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('given_to', 'include')}
                            options={this.validOptions(
                              ADDITIONAL_DETAILS_GIVEN_TO,
                              this.valueIncludeExclude('given_to', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'given_to', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="given_to"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('given_to', 'exclude')}
                            options={this.validOptions(
                              ADDITIONAL_DETAILS_GIVEN_TO,
                              this.valueIncludeExclude('given_to', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'given_to', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field infostatus', styles.field, styles.block, styles.customIncludes)}>
                    <div className="control">
                      <label htmlFor="info_status">
                        <p className={classNames('title', styles.title)}>P/C Status</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="info_status"
                            isMulti
                            className="custom-select with-include "
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('info_status', 'include')}
                            options={this.validOptions(
                              ADDITIONAL_DETAILS_STATUSES,
                              this.valueIncludeExclude('info_status', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'info_status', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="info_status"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('info_status', 'exclude')}
                            options={this.validOptions(
                              ADDITIONAL_DETAILS_STATUSES,
                              this.valueIncludeExclude('info_status', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'info_status', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classNames('field pcnotes', styles.field)}>
                    <div className="pc_notes">
                      <label htmlFor="pc_notes">
                        <p className={classNames('title', styles.title)}>PC Notes</p>
                      </label>
                      <div className={styles.selectFields}>
                        <input
                          maxLength="999"
                          name="pc_notes"
                          disabled={this.type('pc_notes') === 'empty' || this.type('pc_notes') === 'not_empty'}
                          className="input is-large"
                          type="text"
                          placeholder="Notes"
                          value={this.value('pc_notes')}
                          onChange={this.changeBaseInput}
                        />
                        <Select
                          name="pc_notes"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={TYPE_OPTIONS.find(el => el.value === this.type('pc_notes'))}
                          options={TYPE_OPTIONS}
                          onChange={this.changeType}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.checkboxWrapper}>
                    <div className={classNames('field uninsurablecontact', styles.field)}>
                      <label htmlFor="uninsurable_contact">
                        <p className={classNames('title', styles.title)}>Uninsurable Contact</p>
                      </label>
                      <input
                        id="uninsurable_contact"
                        type="checkbox"
                        name="uninsurable_contact"
                        className="is-checkradio is-medium"
                        onChange={() => this.changeBoolean('uninsurable_contact')}
                        checked={this.value('uninsurable_contact')}
                      />
                      <label htmlFor="uninsurable_contact" />
                    </div>
                    <div className={classNames('field uninsurablespouse', styles.field)}>
                      <label htmlFor="uninsurable_spouse">
                        <p className={classNames('title', styles.title)}>Uninsurable Spouse</p>
                      </label>
                      <input
                        id="uninsurable_spouse"
                        type="checkbox"
                        name="uninsurable_spouse"
                        className="is-checkradio is-medium"
                        onChange={() => this.changeBoolean('uninsurable_spouse')}
                        checked={this.value('uninsurable_spouse')}
                      />
                      <label htmlFor="uninsurable_spouse" />
                    </div>
                  </div>
                  <AdvancedSearchItem
                    label="Health Notes"
                    param="health_notes"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />

                  <AdvancedSearchItem
                    label="Current Monthly Income (Contact)"
                    param="contact_current_income"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Current Monthly Income (Spouse)"
                    param="spouse_current_income"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Social Security Monthly (Contact)"
                    param="contact_social_security"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Social Security Monthly (Spouse)"
                    param="spouse_social_security"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Pension Monthly (Contact)"
                    param="contact_pension"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Pension Monthly (Spouse)"
                    param="spouse_pension"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Survivor Pension % (Contact)"
                    param="contact_survivor_pension"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Survivor Pension % (Spouse)"
                    param="spouse_survivor_pension"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Total Monthly Income (Contact)"
                    param="contact_total_income"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Total Monthly Income (Spouse)"
                    param="spouse_total_income"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Other Monthly Household Income (Contact)"
                    param="other_household_income"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Other Monthly Household Income (Spouse)"
                    param="total_household_income"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Mortgage Balance"
                    param="mortgage_balance"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Total Net Worth"
                    param="total_net_worth"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Amount of Inheritance"
                    param="inheritance_amount"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <div className={classNames('field inheritance', styles.field)}>
                    <div className={styles.checkboxWrapper}>
                      <div className={classNames('field inheritance', styles.field)}>
                        <label htmlFor="inheritance">
                          <p className={classNames('title', styles.title)}>Inheritance</p>
                        </label>
                        <input
                          id="inheritance"
                          type="checkbox"
                          name="inheritance"
                          className="is-checkradio is-medium"
                          onChange={() => this.changeBoolean('inheritance')}
                          checked={this.value('inheritance')}
                        />
                        <label htmlFor="inheritance" />
                      </div>
                    </div>
                  </div>

                  <AdvancedSearchItem
                    label="Inheritance Notes"
                    param="inheritance_notes"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Investment/Assets Notes"
                    param="investment_notes"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Asset Protection/LTC"
                    param="asset_protection"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Final Arrangements"
                    param="final_arrangements"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Estate Planning"
                    param="estate_planning"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <div className={classNames('field securitytype', styles.field, styles.block, styles.customIncludes)}>
                    <div className="control">
                      <label htmlFor="security_type">
                        <p className={classNames('title', styles.title)}>Security Type</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="security_type"
                            isMulti
                            className="custom-select with-include "
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('security_type', 'include')}
                            options={this.validOptions(
                              SECURITIES_TYPE,
                              this.valueIncludeExclude('security_type', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'security_type', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="security_type"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('security_type', 'exclude')}
                            options={this.validOptions(
                              SECURITIES_TYPE,
                              this.valueIncludeExclude('security_type', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'security_type', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames('field securitysource', styles.field, styles.block, styles.customIncludes)}
                  >
                    <div className="control">
                      <label htmlFor="security_source">
                        <p className={classNames('title', styles.title)}>Security Source</p>
                      </label>
                      <div className={styles.includesWrapper}>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Include:</p>
                          <Select
                            name="security_source"
                            isMulti
                            className="custom-select with-include "
                            classNamePrefix="custom-select"
                            placeholder="Type to include..."
                            value={this.valueIncludeExclude('security_source', 'include')}
                            options={this.validOptions(
                              SECURITIES_SOURCE,
                              this.valueIncludeExclude('security_source', 'exclude')
                            )}
                            onChange={e => this.changeSelect(e, 'security_source', 'include')}
                          />
                        </div>
                        <div className={styles.includeItem}>
                          <p className={styles.description}>Exclude:</p>
                          <Select
                            name="security_source"
                            isMulti
                            className="custom-select with-include"
                            classNamePrefix="custom-select"
                            placeholder="Type to exclude..."
                            value={this.valueIncludeExclude('security_source', 'exclude')}
                            options={this.validOptions(
                              SECURITIES_SOURCE,
                              this.valueIncludeExclude('security_source', 'include')
                            )}
                            onChange={e => this.changeSelect(e, 'security_source', 'exclude')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <AdvancedSearchItem
                    label="Security Amount"
                    param="security_amount"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Security Owner"
                    param="security_owner"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <AdvancedSearchItem
                    label="Security Notes"
                    param="security_notes"
                    value={this.value}
                    type={this.type}
                    changeType={this.changeType}
                    changeBaseInput={this.changeBaseInput}
                    submitOnEnter={this.submitOnEnter}
                    options={TYPE_OPTIONS}
                  />
                  <div className={classNames('field securitycreated', styles.field)}>
                    <div className="security_created">
                      <label htmlFor="security_created">
                        <p className={classNames('title', styles.title)}>Security Created</p>
                      </label>
                      <div className={styles.dateFields}>
                        {this.type('security_created') !== 'range' && (
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            closeOnScroll={true}
                            id="security_created"
                            maxDate={new Date()}
                            selected={parseDateUTC(this.value('security_created'))}
                            onChange={e => this.changeDate(e, 'security_created')}
                            name="security_created"
                            placeholderText="mm/dd/yyyy"
                            dateFormat="MM/dd/yyyy"
                            className={classNames('input is-large', styles.dateStart)}
                            disabled={
                              this.type('security_created') === 'all' || this.type('security_created') === 'none'
                            }
                          />
                        )}

                        {this.type('security_created') === 'range' && (
                          <>
                            <DatePicker
                              showYearDropdown
                              showMonthDropdown
                              dropdownMode="select"
                              closeOnScroll={true}
                              maxDate={new Date()}
                              selected={parseDateUTC(this.valueRangeStart('security_created'))}
                              onChange={e => this.changeDateRangeStart(e, 'security_created')}
                              name="security_created"
                              placeholderText="mm/dd/yyyy"
                              dateFormat="MM/dd/yyyy"
                              className={classNames('input is-large', styles.dateStart)}
                            />
                            <DatePicker
                              showYearDropdown
                              showMonthDropdown
                              dropdownMode="select"
                              closeOnScroll={true}
                              maxDate={new Date()}
                              minDate={parseDateUTC(this.valueRangeStart('security_created'))}
                              selected={parseDateUTC(this.valueRangeEnd('security_created'))}
                              onChange={e => this.changeDateRangeEnd(e, 'security_created')}
                              name="security_created"
                              disabled={!this.valueRangeStart('security_created')}
                              placeholderText="mm/dd/yyyy"
                              dateFormat="MM/dd/yyyy"
                              className={classNames('input is-large', styles.dateEnd)}
                            />
                          </>
                        )}
                        <Select
                          name="security_created"
                          className={classNames('custom-select', styles.customSelect)}
                          classNamePrefix="custom-select"
                          value={DATE_OPTIONS.find(el => el.value === this.type('security_created'))}
                          options={DATE_OPTIONS}
                          onChange={this.changeDateType}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {this.isRM && (
                <RmAdvancedSearchItems
                  value={this.value}
                  type={this.type}
                  changeType={this.changeType}
                  changeBaseInput={this.changeBaseInput}
                  sources={this.props.sources}
                  changeSelect={this.changeSelect}
                  validOptions={this.validOptions}
                  valueIncludeExclude={this.valueIncludeExclude}
                  removeMultipleValue={this.removeMultipleValue}
                  removeMultipleValues={this.removeMultipleValues}
                  addMultipleValue={this.addMultipleValue}
                  stateZip={this.state.ba_zip_code}
                  homeAddressZip={this.state.home_address_zip_code}
                  changeDate={this.changeDate}
                  changeDateRangeStart={this.changeDateRangeStart}
                  changeDateRangeEnd={this.changeDateRangeEnd}
                  valueRangeEnd={this.valueRangeEnd}
                  valueRangeStart={this.valueRangeStart}
                  changeDateType={this.changeDateType}
                  changeUser={this.changeUser}
                  loadOwners={this.loadOwners}
                />
              )}
            </form>
            {this.duplicateForm}
          </div>
        </div>
        <BottomSidebar
          submitText="Save"
          resetEnable={true}
          onReset={this.onReset}
          onCancel={() => window.history.back()}
          onSubmit={this.onSubmit}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  statuses: state.contacts.contact_statuses,
  sources: state.contacts.contact_sources,
  allTags: [...(state.contacts.tags ? new Map(state.contacts.tags.map(item => [item['name'], item])).values() : [])],
  accountType: state.currentUser.profile.account_type
});

export default connect(mapStateToProps, {
  fetchCarriers,
  searchUsers,
  fetchTags,
  fetchStatuses,
  fetchSources,
  clearContacts
})(withAuthSync(Index));
